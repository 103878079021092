import { Button } from '@material-tailwind/react';

interface DiallerButtonProps {
  onClick: () => void;
  customClasses?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const DiallerButton: React.FC<DiallerButtonProps> = ({
  onClick,
  customClasses = '',
  disabled = false,
  children,
}) => {
  const buttonClasses = `w-[70px] p-0 mb-2 rounded-lg text-center
    h-10 text-2xl border transition-colors duration-200
    text-blue-600 bg-white hover:bg-gray-200 border-blue-600`;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={`${buttonClasses} ${customClasses}`}
    >
      {children}
    </Button>
  );
};

export default DiallerButton;
