// src/hooks/api/available-phones.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { Phone, PhonesService } from 'src/api/generated';

interface AvailablePhonesResponse extends ApiResponse {
  phones: Phone[];
}

export const useAvailablePhones = (): AvailablePhonesResponse => {
  const fetchPhones = async (): Promise<Phone[]> => {
    return await PhonesService.getAvailablePhones();
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['phones/available'],
    queryFn: fetchPhones,
  });

  return {
    loading: isLoading,
    error,
    phones: data || [],
    refetch,
  };
};

interface AssignedPhoneResponse extends ApiResponse {
  phone: Phone | null;
}

export const useAssignedPhone = (): AssignedPhoneResponse => {
  const fetchPhone = async (): Promise<Phone> => {
    return await PhonesService.getAssignedPhone();
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['phones/available/assigned'],
    queryFn: fetchPhone,
  });

  return {
    loading: isLoading,
    error,
    phone: data || null,
    refetch,
  };
};
