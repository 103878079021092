import { useMemo } from 'react';
import { CallWithAnalysisAndUser, ContactWithCalls } from 'src/api/generated';
import { useAnalysisValues } from './api/analysis-values/analysis-values';
import { getCallAnalysis } from 'src/utils/calls';
import { useAnalysisValueViews } from './api/analysis-values/analysis-value-views';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';

export const useAnalysisValuesFromCalls = (
  calls: CallWithAnalysisAndUser[]
) => {
  const callAnalysisIds = useMemo(
    () => getAnalysisIdsFromCalls(calls),
    [calls]
  );

  return useAnalysisValues(callAnalysisIds);
};

export const useAnalysisValuesFromContacts = (contacts: ContactWithCalls[]) => {
  const callsIds = useMemo(() => getCallsIdsFromContacts(contacts), [contacts]);

  const filters: Record<string, FilterParam> = {
    callId: { value: callsIds },
  };
  const filterBy = generateFilterQueryString(filters);
  return useAnalysisValueViews({ filterBy });
};

const getAnalysisIdsFromCalls = (
  calls: CallWithAnalysisAndUser[]
): number[] => {
  const getAnalysisId = (call: CallWithAnalysisAndUser) => {
    const callAnalysis = getCallAnalysis(call);
    return callAnalysis?.id;
  };
  const callAnalysisIds = calls.map(getAnalysisId);
  const filteredCallAnalysisIds = callAnalysisIds.filter(
    (id) => id !== undefined
  ) as number[];

  return filteredCallAnalysisIds;
};

const getCallsIdsFromContacts = (contacts: ContactWithCalls[]): number[] => {
  const getContactCallsIds = (contact: ContactWithCalls) => {
    const contactCalls = contact.calls;
    if (!contactCalls) return [];
    return contactCalls.map((call) => call.id);
  };

  const contactCallsIds = contacts.map(getContactCallsIds);
  const callsIds = contactCallsIds.flat();
  return callsIds;
};
