import { createFileRoute } from '@tanstack/react-router';
import InfoError from 'components/atoms/info-error';
import { AuthenticatedWrapper } from 'components/molecules/auth';
import PipedrivePhone from 'components/organisms/integrations/pipedrive-phone';
import { useInitPipedriveSdk } from 'hooks/integrations/pipedrive/pipedrive';
import { z } from 'zod';

const DEVELOPMENT_ENV = import.meta.env.DEV;

// Example to add search params
// https://tanstack.com/router/latest/docs/framework/react/guide/search-params
const pipedriveIntegrationSearchSchema = z.object({
  id: z.string().catch(''),
  companyId: z.number().catch(0),
  userId: z.number().catch(0),
});

export const Route = createFileRoute('/integrations/pipedrive/')({
  component: PipedriveIntegrationComponent,
  validateSearch: (search) => pipedriveIntegrationSearchSchema.parse(search),
});

// Example to add nested routes
// https://tanstack.com/router/latest/docs/framework/react/examples/basic-file-based
function PipedriveIntegrationComponent() {
  const searchParams = Route.useSearch();
  let { id: appExtensionId, companyId, userId } = searchParams;

  if (DEVELOPMENT_ENV) {
    appExtensionId = '';
    companyId = 0;
    userId = 0;
  }
  const { sdk } = useInitPipedriveSdk(appExtensionId);

  // TODO: eventually auto-login using these parameters
  console.log({ id: appExtensionId, companyId, userId });

  if (!appExtensionId && !DEVELOPMENT_ENV) {
    return <InfoError />;
  }

  const containterClasses = 'flex h-[420px] w-[300px] border border-blue-100';
  return (
    <AuthenticatedWrapper openInPopup className={containterClasses}>
      <PipedrivePhone sdk={sdk} className={containterClasses} />
    </AuthenticatedWrapper>
  );
}
