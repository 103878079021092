/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Record_string_any_ } from './Record_string_any_';

export type FunctionCallServerMessage = {
    /**
     * This is the function call content.
     * @deprecated
     */
    functionCall: Record_string_any_;
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record_string_any_;
    /**
     * This is the main `call` object of the call.
     */
    call: Record_string_any_;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record_string_any_;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record_string_any_;
    /**
     * This is the type of the message. "function-call" is sent to call a function.
     * @deprecated
     */
    type: FunctionCallServerMessage.type;
};

export namespace FunctionCallServerMessage {

    /**
     * This is the type of the message. "function-call" is sent to call a function.
     */
    export enum type {
        FUNCTION_CALL = 'function-call',
    }


}

