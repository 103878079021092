import SidebarButton from 'components/atoms/buttons/sidebar-button';
import {
  BookOpenIcon,
  ChartBarIcon,
  FunnelIcon,
  PhoneIcon,
  StarIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import { SettingName } from 'src/api/generated';
import { Route as ReportsIndexRoute } from 'src/routes/reports.index';
import { Route as CallsIndexRoute } from 'src/routes/calls.index';
import { Route as ContactsIndexRoute } from 'src/routes/contacts.index';
import { Route as FeaturedCallsIndexRoute } from 'src/routes/featured-calls.index';
import { Route as OpportunitiesIndexRoute } from 'src/routes/opportunities.index';
import { Route as UsersIndexRoute } from 'src/routes/users.index';
import SidebarLogoutButton from '../buttons/sidebar-logout-button';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';

const Sidebar = () => {
  const crmActive = useFeatureFlagActive(SettingName.FEATURE_FLAG_CRM);

  return (
    <div className="bg-blue-600 h-full min-h-screen p-4 text-white flex flex-col justify-between overflow-y-auto">
      <div>
        <h2 className="font-bold text-xl mt-4 mb-8 mx-2">LlamadaPro</h2>
        <nav className="space-y-4">
          {/* TODO: check when implementing i18n */}
          <SidebarButton
            to={ReportsIndexRoute.to}
            icon={<ChartBarIcon className="h-6 w-6" />}
            label="Reportes"
          />
          <SidebarButton
            to={CallsIndexRoute.to}
            icon={<PhoneIcon className="h-6 w-6" />}
            label="Llamadas"
          />
          <SidebarButton
            to={OpportunitiesIndexRoute.to}
            icon={<FunnelIcon className="h-6 w-6" />}
            label="Seguimiento"
            hidden={!crmActive}
          />
          <SidebarButton
            to={ContactsIndexRoute.to}
            icon={<BookOpenIcon className="h-6 w-6" />}
            label="Prospectos"
            hidden={crmActive}
          />
          <SidebarButton
            to={UsersIndexRoute.to}
            icon={<UsersIcon className="h-6 w-6" />}
            label="Usuarios"
          />
          <SidebarButton
            to={FeaturedCallsIndexRoute.to}
            icon={<StarIcon className="h-6 w-6" />}
            label="Destacadas"
          />
          <SidebarButton
            to={ContactsIndexRoute.to}
            icon={<BookOpenIcon className="h-6 w-6" />}
            label="Contactos"
            hidden={!crmActive}
          />
        </nav>
      </div>
      <div className="my-14">
        <SidebarLogoutButton />
      </div>
    </div>
  );
};

export default Sidebar;
