import React from 'react';
import { useUpdateUser, useAgents } from 'hooks/api/users';
import ViewContainer from 'components/atoms/view-container';
import AdvancedTable from 'components/molecules/tables/advanced-table';
import { TableRowProps } from 'components/molecules/tables/table';
import { User } from 'src/api/generated';
import EditableTableCell from 'components/molecules/tables/editable-cell-text-input';
import { useAlert } from 'src/contexts/alert-context';
import InfoLoading from 'components/atoms/info-loading';
import InfoError from 'components/atoms/info-error';
import { isValidPhoneNumber } from 'src/utils/validation/phone';

const UsersView: React.FC = () => {
  const { users, loading: usersLoading, error: usersError } = useAgents();
  const { showAlert } = useAlert();

  // TODO: check when implementing i18n
  const handleUserUpdateSuccess = () => {
    showAlert('El número se ha guardado correctamente.', 'success');
  };
  const handleUserUpdateError = () => {
    showAlert(
      `Ocurrió un error actualizando el número. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const { updateUser, loading: updateUserLoading } = useUpdateUser(
    handleUserUpdateSuccess,
    handleUserUpdateError
  );

  const handlePhoneNumberSave = async (
    userId: number,
    newPhoneNumber: string
  ): Promise<boolean> => {
    if (!isValidPhoneNumber(newPhoneNumber)) {
      showAlert(
        'El número de teléfono no es válido. Asegúrate de que el formato sea +XXXXXXXXXXX.',
        'error'
      );
      return false;
    }
    updateUser({
      userId,
      updatedUser: {
        individualPhoneNumber: newPhoneNumber,
      },
    });
    return true;
  };

  const columns = [
    {
      header: 'Nombre',
      accessor: 'fullName',
    },
    {
      header: 'Teléfono Individual',
      accessor: 'phoneNumber',
    },
  ];

  const userPhoneNumberElement = (user: User) => (
    <EditableTableCell
      content={user.individualPhoneNumber}
      onSave={async (newPhoneNumber: string): Promise<boolean> =>
        await handlePhoneNumberSave(user.id, newPhoneNumber)
      }
      loading={usersLoading || updateUserLoading}
    />
  );

  const formattedUsers: TableRowProps[] = users
    .filter((user) => user.firstName !== '' || user.lastName !== '')
    .map((user) => {
      const tableRow: TableRowProps = {
        key: user.id.toString(),
        row: [
          {
            accesor: 'fullName',
            value: `${user.firstName} ${user.lastName}`,
          },
          {
            accesor: 'phoneNumber',
            value: user.individualPhoneNumber,
            element: userPhoneNumberElement(user),
          },
        ],
      };

      return tableRow;
    });

  const filterOptions = [
    {
      label: 'Todos',
      filters: [],
    },
  ];

  if (usersLoading) {
    return <InfoLoading />;
  }

  if (usersError) {
    return <InfoError error={usersError} />;
  }

  return (
    // TODO: check when implementing i18n
    <ViewContainer title="Usuarios">
      <AdvancedTable
        columns={columns}
        data={formattedUsers}
        filterOptions={filterOptions}
      />
    </ViewContainer>
  );
};

export default UsersView;
