/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Condition } from './Condition';

export type ToolMessageDelayed = {
    /**
     * This is an optional array of conditions that the tool call arguments must meet in order for this message to be triggered.
     */
    conditions?: Array<Condition>;
    /**
     * This is the content that the assistant says when this message is triggered.
     */
    content: string;
    /**
     * The number of milliseconds to wait for the server response before saying this message.
     */
    timingMilliseconds?: number;
    /**
     * This message is triggered when the tool call is delayed.
     *
     * There are the two things that can trigger this message:
     * 1. The user talks with the assistant while your server is processing the request. Default is "Sorry, a few more seconds."
     * 2. The server doesn't respond within `timingMilliseconds`.
     *
     * This message is never triggered for async tool calls.
     */
    type: ToolMessageDelayed.type;
};

export namespace ToolMessageDelayed {

    /**
     * This message is triggered when the tool call is delayed.
     *
     * There are the two things that can trigger this message:
     * 1. The user talks with the assistant while your server is processing the request. Default is "Sorry, a few more seconds."
     * 2. The server doesn't respond within `timingMilliseconds`.
     *
     * This message is never triggered for async tool calls.
     */
    export enum type {
        REQUEST_RESPONSE_DELAYED = 'request-response-delayed',
    }


}

