import * as Sentry from '@sentry/react';

class ErrorReporter {
  /**
   * Initializes Sentry for a React application.
   * This function configures Sentry with various integrations for error tracking, performance monitoring,
   * and session replay in a React application.
   *
   * IMPORTANT: Call this function as early as possible in the lifecycle of your React application,
   * preferably in the main entry file (e.g., index.js or App.js) before rendering your root component.
   */
  public static initializeSentry = (): void => {
    if (import.meta.env.DEV) {
      return;
    }

    Sentry.init({
      // Sentry Data Source Name (DSN)
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          // Configures the URLs for which distributed tracing should be enabled
          tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
        }),
        // Integration for session replay
        new Sentry.Replay(),
      ],
      // Configures the sampling rate for performance monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay settings
      replaysSessionSampleRate: 0.1, // Sample rate at 10%. Adjust as needed for different environments.
      replaysOnErrorSampleRate: 1.0, // Sample rate at 100% for sessions where errors occur.
      environment: import.meta.env.MODE,
    });
  };

  /**
   * Sends an exception to Sentry.
   *
   * @param error - The exception to be sent to Sentry.
   */
  public static sendException = (error: unknown): void => {
    if (import.meta.env.DEV) {
      console.error({ error });
      return;
    }

    Sentry.captureException(error);
  };

  /**
   * Sends a custom message to Sentry.
   *
   * @param message - The message to be sent to Sentry.
   * @param level - The severity level of the message.
   */
  public static sendMessage = (
    message: string,
    level: Sentry.SeverityLevel
  ): void => {
    if (import.meta.env.DEV) {
      console.log({ message, level });
      return;
    }

    Sentry.captureMessage(message, level);
  };
}

export default ErrorReporter;
