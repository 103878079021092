import {
  AnalysisValueView,
  Call,
  CallAnalysis,
  CallWithAnalysisAndUser,
} from 'src/api/generated';
import { formatDuration } from './formatting/time';
import { getCallAnalysisValues } from './mapping/analysisValues';
import { getLatestContactQualiScore } from './analysisValues/scores/contactQuali';
import { getScoreValue } from './analysisValues/scores/scores';
import { color } from '@material-tailwind/react/types/components/chip';

export type CallDirection = 'incoming' | 'outgoing';
export const isIncomingCall = (call: Call) => call.direction !== 'outgoing';

export const getStatusClass = (callStatus: string): string => {
  const callStatusClassMap: Record<string, string> = {
    busy: 'text-red-600',
    'no-answer': 'text-red-600',
    ringing: 'text-blue-600',
    queued: 'text-blue-600',
    'in-progress': 'text-blue-600',
    failed: 'text-red-600',
    canceled: 'text-red-600',
    completed: '',
  };

  return callStatusClassMap[callStatus] || '';
};

const getStatusText = (callStatus: string): string => {
  // TODO: check when implementing i18n
  const callStatusTextMap: Record<string, string> = {
    busy: 'Rechazada',
    'no-answer': 'No contesta',
    ringing: 'Llamando',
    queued: 'Llamando',
    'in-progress': 'En curso',
    initiated: 'Llamando',
    failed: 'No contesta',
    canceled: 'Cancelada',
    completed: 'Completada',
  };

  return callStatusTextMap[callStatus] || '';
};

export const getDurationText = (
  callStatus: string,
  callDuration: number | null
): string => {
  if (callStatus === 'completed') return formatDuration(callDuration || 0);
  return getStatusText(callStatus);
};

export const getCategoryColor = (category: string): color => {
  const callCategoryColorMap: Record<string, color> = {
    voicemail: 'blue',
    'wrong-number': 'blue',
  };

  return callCategoryColorMap[category] || 'blue';
};

export const getCategoryText = (category: string): string | null => {
  const callCategoryTextMap: Record<string, string> = {
    // TODO: check when implementing i18n
    voicemail: 'Buzón de voz',
    'wrong-number': 'Número equivocado',
  };

  return callCategoryTextMap[category] || null;
};

export const getCallAnalysis = (
  call: CallWithAnalysisAndUser
): CallAnalysis | null => {
  return call.recording?.transcription?.callAnalysis || null;
};

export const getLastCall = (
  calls: CallWithAnalysisAndUser[]
): CallWithAnalysisAndUser | null => {
  const sortedCalls = sortCallsByDate(calls, 'desc');
  if (sortedCalls.length < 1) return null;

  return sortedCalls[0];
};

export const getMaxQualiScore = (
  calls: CallWithAnalysisAndUser[],
  analysisValues: AnalysisValueView[] = []
): number | null => {
  const qualiScores: (number | null)[] = calls.map((call) =>
    getCallQualiScore(call, analysisValues)
  );
  if (qualiScores.length === 0) return null;

  const maxQualiScore: number | null = qualiScores.reduce((max, score) => {
    if (score === null) return max;

    const currentMax = max ?? 0;
    return Math.max(currentMax, score);
  });
  return maxQualiScore;
};

export const sortCallsByDate = (
  calls: CallWithAnalysisAndUser[],
  order: 'asc' | 'desc' = 'asc'
): CallWithAnalysisAndUser[] => {
  const sortCalls = (
    a: CallWithAnalysisAndUser,
    b: CallWithAnalysisAndUser
  ) => {
    if (!a.startTime) return 1;
    if (!b.startTime) return -1;

    return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
  };

  if (calls.length === 0) return [];
  const sortedCalls = calls.sort(sortCalls);
  if (order === 'asc') return sortedCalls;

  return sortedCalls.reverse();
};

export const isCallCompleted = (call: CallWithAnalysisAndUser | Call) => {
  return call.status === 'completed';
};

const isCallMissed = (call: CallWithAnalysisAndUser | Call) => {
  const notMissedStatuses = ['completed', 'in-progress'];
  return !notMissedStatuses.includes(call.status);
};

export const filterCompletedCalls = (calls: CallWithAnalysisAndUser[]) => {
  const completedCalls = calls.filter(isCallCompleted);
  return completedCalls;
};

export const filterMissedCalls = (calls: CallWithAnalysisAndUser[]) => {
  const missedCalls = calls.filter(isCallMissed);
  return missedCalls;
};

export const countCompletedCalls = (calls: CallWithAnalysisAndUser[]) => {
  const completedCalls = filterCompletedCalls(calls);
  return completedCalls.length;
};

export const countMissedCalls = (calls: CallWithAnalysisAndUser[]) => {
  const missedCalls = filterMissedCalls(calls);
  return missedCalls.length;
};

const getCallQualiScore = (
  call: CallWithAnalysisAndUser | null,
  analysisValues: AnalysisValueView[] = []
): number | null => {
  const callAnalysis = call?.recording?.transcription?.callAnalysis;

  if (!callAnalysis) return null;

  const isDeprecated = !!callAnalysis?.version;
  if (isDeprecated) return callAnalysis?.qualiScore ?? null;

  const callAnalysisValues = getCallAnalysisValues(
    analysisValues,
    callAnalysis
  );
  const qualiScore = getLatestContactQualiScore(callAnalysisValues);
  if (!qualiScore) return null;

  return getScoreValue(qualiScore) ?? null;
};
