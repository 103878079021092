import React from 'react';
import { IconButton } from '@material-tailwind/react';
import { XCircleIcon } from '@heroicons/react/24/outline';

interface InlineCancelButtonProps {
  onClick: () => void;
  className?: string;
  iconClassName?: string;
}

const InlineCancelButton: React.FC<InlineCancelButtonProps> = ({
  onClick,
  className = '',
  iconClassName = '',
}) => {
  return (
    <IconButton
      onClick={onClick}
      variant="text"
      className={`w-8 h-8 ${className}`}
    >
      <XCircleIcon
        strokeWidth={2}
        className={`h-6 w-6 text-red-800 ${iconClassName}`}
      />
    </IconButton>
  );
};

export default InlineCancelButton;
