import { ContactWithCalls } from 'src/api/generated';
import ContactCallItem from '../contact-call-item';
import { Typography } from '@material-tailwind/react';
import { sortCallsByDate } from 'src/utils/calls';
import { formatFullName } from 'src/utils/formatting/name';
import InfoLoading from 'components/atoms/info-loading';
import NetworkIndicator from 'components/atoms/network-indicator';
import PhoneConfigModalButton from 'components/molecules/phone/phone-config/phone-config-modal-button';
import { CallWarning, PhoneConfigActions } from 'hooks/phones/types';
import NoInternetModeTooltip from './no-internet-mode-tooltip';

interface ContactDisplayProps {
  contact?: ContactWithCalls;
  loading?: boolean;
  phoneConfigActions: PhoneConfigActions;
  warnings?: CallWarning[];
  callingWithoutInternet: boolean;
}

// TODO: check when implementing i18n
const DISPLAY_TITLE = 'Historial de llamadas';

const ContactDisplay: React.FC<ContactDisplayProps> = ({
  contact,
  loading = false,
  phoneConfigActions,
  warnings,
  callingWithoutInternet = false,
}) => {
  const calls = contact?.calls || [];

  const contactName = formatFullName(contact?.firstName, contact?.lastName);
  const contactCalls = sortCallsByDate(calls, 'desc');

  const callsContainerClasses = `
    flex flex-col overflow-y-auto w-full shadow-inner
    border-y-blue-gray-200 border grow
  `;

  return (
    <div className="flex flex-col w-full grow max-h-[270px]">
      <div className="flex flex-row justify-between p-3 pt-2">
        <Typography className="text-lg text-blue-400">
          {contactName || DISPLAY_TITLE}
        </Typography>
        <div className="flex justify-end items-center">
          <NetworkIndicator warnings={warnings} />
          {callingWithoutInternet ? (
            <NoInternetModeTooltip />
          ) : (
            <PhoneConfigModalButton phoneConfigActions={phoneConfigActions} />
          )}
        </div>
      </div>
      <div className={callsContainerClasses}>
        {loading ? (
          <InfoLoading />
        ) : (
          contactCalls.map((call) => (
            <ContactCallItem key={call.id} call={call} />
          ))
        )}
      </div>
    </div>
  );
};

export default ContactDisplay;
