import { Input } from '@material-tailwind/react';
import React, { ComponentPropsWithoutRef } from 'react';

type InputPropsType = Omit<
  ComponentPropsWithoutRef<typeof Input>,
  'onChange' | 'crossOrigin' | 'className'
>;
interface TextInputProps extends InputPropsType {
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  placeholder?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  onChange,
  className = '',
  placeholder,
  ...inputProps
}) => {
  const classes = `
    border-t-blue-gray-200 focus:border-t-blue-500 focus:border-blue-500
    h-8 ${className}
  `;
  return (
    <Input
      label={placeholder}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={classes}
      labelProps={{ className: `hidden` }}
      containerProps={{ className: 'h-auto h-8' }}
      crossOrigin={undefined}
      {...inputProps}
    />
  );
};

export default TextInput;
