import { useEffect } from 'react';
import { clarity } from 'clarity-js';
import { useAuth0 } from '@auth0/auth0-react';

const CLARITY_PROJECT_ID = import.meta.env.VITE_CLARITY_PROJECT_ID;

export const useClarity = () => {
  const { user } = useAuth0();

  useEffect(() => {
    if (!user) {
      return;
    }

    const userEmail = user?.email ?? '';
    const userName = user?.name ?? '';
    const environment = import.meta.env.MODE;

    clarity.set('user_email', userEmail);
    clarity.set('env', environment);
    clarity.identify(userEmail, userEmail, undefined, userName);
    clarity.start({
      projectId: CLARITY_PROJECT_ID,
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
      cookies: ['user_email'],
    });

    return () => clarity.stop();
  }, [user]);
};
