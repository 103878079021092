import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-tailwind/react';
import { AlertProvider } from './alert-context';
import { PhoneProvider } from './phone-context';
import QueryProvider from './query-provider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import esLocale from 'date-fns/locale/es';
import { ReactNode } from 'react';
import AuthenticatedProviders from './authenticated-providers';

interface ProvidersProps {
  children: ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  const auth0ClientId = import.meta.env.PROD
    ? import.meta.env.VITE_AUTH0_CLIENT_ID
    : import.meta.env.VITE_AUTH0_DEV_CLIENT_ID;

  return (
    <ThemeProvider>
      <AlertProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={esLocale}
        >
          <Auth0Provider
            domain={import.meta.env.VITE_AUTH0_DOMAIN || ''}
            clientId={auth0ClientId || ''}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
              scope:
                'openid profile email read:current_user update:current_user_metadata',
            }}
          >
            <PhoneProvider>
              <QueryProvider>
                <AuthenticatedProviders>{children}</AuthenticatedProviders>
              </QueryProvider>
            </PhoneProvider>
          </Auth0Provider>
        </LocalizationProvider>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default Providers;
