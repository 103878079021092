// src/components/molecules/modals/timed-alert-modal.tsx

import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Progress, Typography } from '@material-tailwind/react';
import Modal from 'components/atoms/modals/modal';
import useActionAfterCountdown from 'hooks/utils/countdown';

interface TimedAlertModalProps {
  message: string;
  title?: string;
  icon?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  durationInMs: number;
}

const PROGRESS_UPDATE_INTERVAL_IN_MS = 10;

const TimedAlertModal: React.FC<TimedAlertModalProps> = ({
  message,
  title,
  icon,
  isOpen,
  onClose,
  durationInMs,
}) => {
  const { progress } = useActionAfterCountdown(
    durationInMs,
    onClose,
    PROGRESS_UPDATE_INTERVAL_IN_MS
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      headerClasses="text-center w-full"
      bodyClasses="mb-0 md:mb-0"
    >
      <div className="flex flex-col items-center justify-center mt-4 mb-0">
        {icon ? (
          icon
        ) : (
          <ExclamationCircleIcon className="h-24 w-24 text-blue-gray-800" />
        )}
        <div className="m-4">
          <Typography className="text-center">{message}</Typography>
        </div>
        <Progress value={progress} size="sm" className="mt-2" />
      </div>
    </Modal>
  );
};

export default TimedAlertModal;
