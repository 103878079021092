// src/hooks/api/api.ts

import ErrorReporter from 'src/monitoring/errorReporter';
import { OpenAPI, ApiError } from '../../api/generated';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { RefetchOptions } from '@tanstack/react-query';
import HttpStatusCode from 'src/types/http-status-code';
import { sleep } from 'src/utils/clock';
import { AlertType } from 'components/molecules/alert/alert';

const MAX_RETRY_NUMBER = 2;
const MIN_RETRY_DELAY_IN_MS = 1000;
const MAX_RETRY_DELAY_IN_MS = 30000;

export interface ApiResponse {
  loading: boolean;
  error: Error | null;
  refetch: (options?: RefetchOptions) => Promise<unknown>;
}

export const useSetApiToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (e) {
      ErrorReporter.sendException(e);
      return '';
    }
  };

  OpenAPI.TOKEN = getToken;
};

export const useMemoizedArrayData = <TDataResult>(
  dataResult: TDataResult | undefined
): TDataResult => {
  const memoizedData = useMemo(
    () => dataResult || ([] as TDataResult),
    [dataResult]
  );
  return memoizedData;
};

export const exponentialBackoffDelay = (retryAttempt: number): number => {
  const exponentialDelay = Math.pow(2, retryAttempt) * MIN_RETRY_DELAY_IN_MS;
  const limitedDelay = Math.min(exponentialDelay, MAX_RETRY_DELAY_IN_MS);
  return limitedDelay;
};

export const shouldRetry = (
  failureCount: number,
  error: Error,
  refreshToken: () => Promise<void>
): boolean => {
  const reachedRetryLimit: boolean = failureCount >= MAX_RETRY_NUMBER;
  if (reachedRetryLimit) {
    return false;
  }
  return shouldRetryError(error, refreshToken);
};

export const shouldRetryError = (
  error: Error,
  refreshToken: () => Promise<void>
): boolean => {
  if (!(error instanceof ApiError)) {
    return true;
  }

  const apiError = error as ApiError;
  if (apiError.status === HttpStatusCode.UNAUTHORIZED) {
    refreshToken();
    return true;
  }
  if (apiError.status === HttpStatusCode.FORBIDDEN) {
    refreshToken();
    return true;
  }
  if (apiError.status === HttpStatusCode.BAD_REQUEST) {
    return false;
  }
  if (apiError.status === HttpStatusCode.NOT_FOUND) {
    return false;
  }
  if (apiError.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
    return true;
  }

  // Retry any other error
  return true;
};

export const onRetryLimitReached = async (
  error: Error,
  logout: () => Promise<void>,
  showAlert: (message: string, type: AlertType) => void
) => {
  if (!(error instanceof ApiError)) {
    return;
  }

  const apiError = error as ApiError;
  if (apiError.status === HttpStatusCode.UNAUTHORIZED) {
    // TODO: check when implementing i18n
    const message =
      'Tu sesión ha finalizado, inicia sesión nuevamente. Si no tienes ' +
      'invitación contacta al administrador de tu equipo.';
    // TODO: when query params implemented add funcionality to display alert
    // with message from query params, to improve user experience
    showAlert(message, 'warning');
    await sleep(5000);

    logout();
    return;
  }
  if (apiError.status === HttpStatusCode.FORBIDDEN) {
    // TODO: check when implementing i18n
    const message =
      'No puedes hacer esto. Asegúrate que tu cuenta tenga los ' +
      'permisos necesarios e intenta nuevamente';
    showAlert(message, 'error');
    return;
  }
};
