/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Record_string_JsonSchema_ } from './Record_string_JsonSchema_';

export type OpenAIFunctionParameters = {
    /**
     * This specifies the properties that are required by the function.
     */
    required?: Array<string>;
    /**
     * This provides a description of the properties required by the function.
     * JSON Schema can be used to specify expectations for each property.
     * Refer to [this doc](https://ajv.js.org/json-schema.html#json-data-type) for a comprehensive guide on JSON Schema.
     */
    properties: Record_string_JsonSchema_;
    /**
     * This must be set to 'object'. It instructs the model to return a JSON object containing the function call properties.
     */
    type: OpenAIFunctionParameters.type;
};

export namespace OpenAIFunctionParameters {

    /**
     * This must be set to 'object'. It instructs the model to return a JSON object containing the function call properties.
     */
    export enum type {
        OBJECT = 'object',
    }


}

