/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Condition } from './Condition';

export type ToolMessageComplete = {
    /**
     * This is an optional array of conditions that the tool call arguments must meet in order for this message to be triggered.
     */
    conditions?: Array<Condition>;
    /**
     * This is the content that the assistant says when this message is triggered.
     */
    content: string;
    /**
     * This is optional and defaults to "assistant".
     *
     * When role=assistant, `content` is said out loud.
     *
     * When role=system, `content` is passed to the model in a system message. Example:
     * system: default one
     * assistant:
     * user:
     * assistant:
     * user:
     * assistant:
     * user:
     * assistant: tool called
     * tool: your server response
     * <--- system prompt as hint
     * ---> model generates response which is spoken
     * This is useful when you want to provide a hint to the model about what to say next.
     */
    role?: ToolMessageComplete.role;
    /**
     * This message is triggered when the tool call is complete.
     *
     * This message is triggered immediately without waiting for your server to respond for async tool calls.
     *
     * If this message is not provided, the model will be requested to respond.
     *
     * If this message is provided, only this message will be spoken and the model will not be requested to come up with a response. It's an exclusive OR.
     */
    type: ToolMessageComplete.type;
};

export namespace ToolMessageComplete {

    /**
     * This is optional and defaults to "assistant".
     *
     * When role=assistant, `content` is said out loud.
     *
     * When role=system, `content` is passed to the model in a system message. Example:
     * system: default one
     * assistant:
     * user:
     * assistant:
     * user:
     * assistant:
     * user:
     * assistant: tool called
     * tool: your server response
     * <--- system prompt as hint
     * ---> model generates response which is spoken
     * This is useful when you want to provide a hint to the model about what to say next.
     */
    export enum role {
        ASSISTANT = 'assistant',
        SYSTEM = 'system',
    }

    /**
     * This message is triggered when the tool call is complete.
     *
     * This message is triggered immediately without waiting for your server to respond for async tool calls.
     *
     * If this message is not provided, the model will be requested to respond.
     *
     * If this message is provided, only this message will be spoken and the model will not be requested to come up with a response. It's an exclusive OR.
     */
    export enum type {
        REQUEST_COMPLETE = 'request-complete',
    }


}

