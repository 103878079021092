import CompactPhone from '../../phones/compact-phone';
import PhoneFooter from '../phone-footer';
import { useInitPipedriveIntegration } from 'hooks/integrations/pipedrive/pipedrive';
import { useRouterState } from '@tanstack/react-router';
import { useCallProvider } from 'hooks/phones/phones';
import AppExtensionsSDK from '@pipedrive/app-extensions-sdk';

interface PipedrivePhoneProps {
  sdk: AppExtensionsSDK | null;
  className?: string;
}

const PipedrivePhone: React.FC<PipedrivePhoneProps> = ({
  sdk,
  className = '',
}) => {
  const callProvider = useCallProvider();
  useInitPipedriveIntegration(sdk, callProvider);

  const router = useRouterState();
  const redirectUrlAfterLogout = `${window.location.origin}${router.location.href}`;

  return (
    <div className={`flex-col ${className}`}>
      <CompactPhone callProvider={callProvider} />
      <PhoneFooter redirectUrlAfterLogout={redirectUrlAfterLogout} />
    </div>
  );
};

export default PipedrivePhone;
