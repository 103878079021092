import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/atoms/buttons/button';
import React from 'react';

interface LogoutButtonProps {
  className?: string;
  children?: React.ReactNode;
  redirectUrlAfterLogout?: string;
}

const logoutInNewTab = (url: string) => {
  const newTab = window.open(url, '_blank');
  newTab?.close();
};

const LogoutButton: React.FC<LogoutButtonProps> = ({
  className = '',
  children,
  redirectUrlAfterLogout,
}) => {
  const { logout } = useAuth0();

  const baseClasses = ``;
  const classes = `${baseClasses} ${className}`;

  const returnTo = redirectUrlAfterLogout ?? window.location.origin;

  return (
    <Button
      onClick={() => {
        logout({
          logoutParams: {
            returnTo,
          },
          openUrl: logoutInNewTab,
        });
      }}
      customClasses={classes}
    >
      {/* TODO: check when implementing i18n */}
      {children ? children : 'Cerrar sesión'}
    </Button>
  );
};

export default LogoutButton;
