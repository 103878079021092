import { ReactNode } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

interface ToggleIconProps {
  isDrawerOpen: boolean;
}

function ToggleIcon({ isDrawerOpen }: ToggleIconProps) {
  const openClass = isDrawerOpen ? 'rotate-180' : '';
  return (
    <ChevronUpIcon
      strokeWidth={2}
      className={`h-5 w-5 transition-transform ${openClass}`}
    />
  );
}

interface FixedDrawerProps {
  isOpen: boolean;
  onToggle: () => void;
  headerContent?: ReactNode;
  bodyContent?: ReactNode;
  icon?: ReactNode;
  className?: string;
  bodyClassName?: string;
}

function FixedDrawer({
  isOpen,
  onToggle,
  headerContent,
  bodyContent,
  className = '',
  bodyClassName = '',
}: FixedDrawerProps) {
  const accordionClasses = `
    fixed z-50 bottom-0 right-0 bg-white rounded-t-lg shadow-xl
    w-full md:w-fit md:right-3 max-h-screen overflow-y-auto 
    ${className}
  `;
  const headerClasses = `
    py-2 px-3 bg-blue-600 hover:bg-blue-700
    text-white hover:text-white rounded-t-lg
  `;
  const bodyClasses = `border-blue-700 border-x-2 pt-1 ${bodyClassName}`;
  return (
    <Accordion
      open={isOpen}
      className={accordionClasses}
      icon={<ToggleIcon isDrawerOpen={isOpen} />}
    >
      <AccordionHeader onClick={onToggle} className={headerClasses}>
        {headerContent}
      </AccordionHeader>
      <AccordionBody className={bodyClasses}>{bodyContent}</AccordionBody>
    </Accordion>
  );
}

export default FixedDrawer;
