// src/app.tsx

import Chat from 'components/atoms/chat';
import { useAuth0 } from '@auth0/auth0-react';
import { useClarity } from 'hooks/clarity';
import InfoLoading from 'components/atoms/info-loading';
import AuthenticatedApp from 'components/organisms/app-layouts/authenticated-app';
import UnauthenticatedApp from 'components/organisms/app-layouts/unauthenticated-app';
import { useMatchRoute, Outlet } from '@tanstack/react-router';

export function App() {
  useClarity();
  const { isAuthenticated, isLoading } = useAuth0();
  const matchRoute = useMatchRoute();

  // TODO: try to do it for /integrations/*
  const pipedriveParams = matchRoute({
    to: '/integrations/pipedrive',
    fuzzy: true,
  });

  if (isLoading) {
    return <InfoLoading />;
  }

  if (pipedriveParams) {
    return <Outlet />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}

      <Chat
        providerName="tawk"
        propertyId="65a985290ff6374032c1f8be"
        widgetId="1hkf30b1b"
      />
    </div>
  );
}
