export type CallQuality = 'LOW' | 'MID' | 'HIGH';

// https://twilio.github.io/twilio-voice.js/interfaces/voice.device.options.html#maxaveragebitrate
// 8000-40000 recommended by twilio
export const CALL_QUALITY_BITRATES: Record<CallQuality, number> = {
  LOW: 6000,
  MID: 20000,
  HIGH: 400000,
};
export const DEFAULT_DEVICE_BITRATE = CALL_QUALITY_BITRATES.MID;
