import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  formatPhoneNumberAsYouType,
  standardizePhoneNumber,
} from 'src/utils/formatting/phone';
import STATES from 'states/index';

const INITIAL_NUMBER = '';
const INITIAL_VISIBILITY = false;

interface PhoneContextType {
  formattedPhoneNumber: string;
  standardizedPhoneNumber: string | null;
  validNumberSelected: boolean;
  setPhoneNumber: (number: string) => void;
  resetPhoneNumber: () => void;
  initialNumber: string;
  phoneState: string;
  setPhoneState: (number: string) => void;
  keyboardVisible: boolean;
  setKeyboardVisible: (visibility: boolean) => void;
}

const PhoneContext = createContext<PhoneContextType | undefined>(undefined);

export const usePhoneContext = () => {
  const context = useContext(PhoneContext);
  if (!context) {
    throw new Error('usePhoneContext must be used within a PhoneProvider');
  }
  return context;
};

interface PhoneProviderProps {
  children: ReactNode;
}

export const PhoneProvider: React.FC<PhoneProviderProps> = ({ children }) => {
  const [phoneState, setPhoneState] = useState<string>(STATES.PHONE.CONNECTING);
  const [formattedPhoneNumber, setFormattedPhoneNumber] =
    useState<string>(INITIAL_NUMBER);
  const [standardizedPhoneNumber, setStandardizedPhoneNumber] = useState<
    string | null
  >(null);
  const [keyboardVisible, setKeyboardVisible] =
    useState<boolean>(INITIAL_VISIBILITY);

  const setNumber = useCallback(
    (number: string) => {
      const formatted = formatPhoneNumberAsYouType(number);
      const standardized = standardizePhoneNumber(number);

      setFormattedPhoneNumber(formatted);
      setStandardizedPhoneNumber(standardized);
      setKeyboardVisible(true);
    },
    [setFormattedPhoneNumber, setStandardizedPhoneNumber, setKeyboardVisible]
  );

  const resetPhoneNumber = useCallback(
    () => setNumber(INITIAL_NUMBER),
    [setNumber]
  );

  const validNumberSelected: boolean = !!standardizedPhoneNumber;

  return (
    <PhoneContext.Provider
      value={{
        formattedPhoneNumber,
        standardizedPhoneNumber,
        validNumberSelected,
        setPhoneNumber: setNumber,
        resetPhoneNumber,
        initialNumber: INITIAL_NUMBER,
        phoneState,
        setPhoneState,
        keyboardVisible,
        setKeyboardVisible,
      }}
    >
      {children}
    </PhoneContext.Provider>
  );
};
