// src/hooks/api/analysis-values/analysis-value-views.ts

import { useQuery } from '@tanstack/react-query';
import {
  AnalysisService,
  AnalysisValueView,
  ApiQueryParams,
  PaginatedData_AnalysisValueView_,
} from 'src/api/generated';
import { ApiResponse, useMemoizedArrayData } from '../api';

interface AnalysisValueViewsResponse extends ApiResponse {
  analysisValueViews: AnalysisValueView[];
  totalPages: number;
}

export const useAnalysisValueViews = (
  queryParams: ApiQueryParams = {},
  enabled: boolean = true
): AnalysisValueViewsResponse => {
  const fetchAnalysisValueViews =
    async (): Promise<PaginatedData_AnalysisValueView_> => {
      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

      return await AnalysisService.getAnalysisValueViews(
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['analysis-values/views', queryParams],
    queryFn: fetchAnalysisValueViews,
    enabled,
  });
  const analysisValueViews = useMemoizedArrayData<AnalysisValueView[]>(
    data?.data
  );
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    analysisValueViews,
    totalPages,
    refetch,
  };
};
