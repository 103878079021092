// src/components/molecules/phone/phone-config/phone-config-modal/phone-config-modal-button.tsx

import Modal from 'components/atoms/modals/modal';
import PhoneConfig from '../phone-config';
import { useState } from 'react';
import { IconButton } from '@material-tailwind/react';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { PhoneConfigActions } from 'hooks/phones/types';

interface PhoneConfigModalButtonProps {
  phoneConfigActions: PhoneConfigActions;
}

const PhoneConfigModalButton: React.FC<PhoneConfigModalButtonProps> = ({
  phoneConfigActions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        variant="text"
        className={`w-8 h-8`}
      >
        <Cog6ToothIcon strokeWidth={2} className="h-6 w-6 text-blue-gray-600" />
      </IconButton>
      <Modal
        // TODO: check when implementing i18n
        title="Configuración del teléfono"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <PhoneConfig phoneConfigActions={phoneConfigActions} />
      </Modal>
    </>
  );
};

export default PhoneConfigModalButton;
