// src/components/atoms/tooltip-text/tooltip-text.tsx

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';

interface TextTooltipProps {
  content: string;
  tooltipContent: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const TextTooltip: React.FC<TextTooltipProps> = ({
  content,
  tooltipContent,
  placement = 'top',
}) => {
  return (
    <div className="flex flex-row">
      <Typography variant="small" className="font-normal">
        {content}
      </Typography>
      <Tooltip
        title={tooltipContent}
        placement={placement}
        className="border border-blue-gray-50 shadow-xl ml-1 shadow-black/10 break-normal"
        PopperProps={{
          // workaround to avoid tooltip being hidden behind modals
          style: { zIndex: 10000 },
        }}
      >
        <InformationCircleIcon className="h-5 w-5" />
      </Tooltip>
    </div>
  );
};

export default TextTooltip;
