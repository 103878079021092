/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Record_string_any_ } from './Record_string_any_';

export type VoiceInputServerMessage = {
    /**
     * This is the voice input content
     */
    input: string;
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record_string_any_;
    /**
     * This is the main `call` object of the call.
     */
    call: Record_string_any_;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record_string_any_;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record_string_any_;
    /**
     * This is the type of the message. "voice-input" is sent when a generation is requested from voice provider.
     */
    type: VoiceInputServerMessage.type;
};

export namespace VoiceInputServerMessage {

    /**
     * This is the type of the message. "voice-input" is sent when a generation is requested from voice provider.
     */
    export enum type {
        VOICE_INPUT = 'voice-input',
    }


}

