import { IModel, UpdateListCacheParams } from 'src/types/cache';

export const updateListCache = <TModel extends IModel>({
  queryClient,
  queryKey,
  updatedData,
}: UpdateListCacheParams<TModel>) => {
  queryClient.setQueryData(queryKey, (oldData: TModel[]) => {
    if (!oldData) return [];

    const updatedIndex = oldData.findIndex(
      (item) => item.id === updatedData.id
    );

    const updatedCache: TModel[] = [...oldData];
    if (updatedIndex !== -1) {
      updatedCache[updatedIndex] = updatedData;
    } else {
      updatedCache.push(updatedData);
    }

    return updatedCache;
  });
};
