import React, { useState } from 'react';
import InlineSaveButton from 'components/atoms/buttons/inline-save-button';
import InlineCancelButton from 'components/atoms/buttons/inline-cancel-button';
import InlineEditButton from 'components/atoms/buttons/inline-edit-button';
import { Spinner, Typography } from '@material-tailwind/react';
import TextArea from 'components/atoms/forms/text-area';

interface EditableCellTextAreaProps {
  content: React.ReactNode;
  onSave: (newValue: string) => Promise<boolean>;
  loading?: boolean;
  className?: string;
}

const EditableCellTextArea: React.FC<EditableCellTextAreaProps> = ({
  content,
  onSave,
  loading,
  className = '',
}) => {
  const [editMode, setEditMode] = useState(false);
  const textContent = typeof content === 'string' ? content : '';
  const [inputValue, setInputValue] = useState(textContent);
  const [originalValue] = useState(textContent);

  const handleSave = async () => {
    const success: boolean = await onSave(inputValue);
    if (success) {
      setEditMode(false);
    }
  };

  const handleCancel = () => {
    setInputValue(originalValue);
    setEditMode(false);
  };

  const containerClasses = `flex w-64 items-center space-x-1 ${className}`;

  return (
    <div className={containerClasses}>
      {editMode ? (
        <>
          <TextArea
            defaultValue={inputValue}
            onChange={setInputValue}
            className="flex-1"
          />
          {loading ? (
            <Spinner className="h-4 w-4" />
          ) : (
            <>
              <InlineSaveButton onClick={handleSave} />
              <InlineCancelButton onClick={handleCancel} />
            </>
          )}
        </>
      ) : (
        <>
          <Typography className="flex-1 text-sm whitespace-pre-line">
            {content}
          </Typography>
          <InlineEditButton onClick={() => setEditMode(true)} />
        </>
      )}
    </div>
  );
};

export default EditableCellTextArea;
