// src/components/molecules/charts/call-type-select/call-type-select.tsx

import { SelectData } from 'tw-elements-react/dist/types/forms/Select/types';
import { CallType } from 'src/types/call-summaries';
import Select from 'components/atoms/select';
import { generateSolidColorIcon } from 'src/utils/charts';

interface CallTypeSelectProps {
  selectedCallType: CallType;
  onCallTypeChange: (selectedCallType: CallType) => void;
}

const callTypeOptions = [
  {
    text: 'Todas las llamadas',
    value: 'all',
  },
  {
    text: 'Solo efectivas',
    value: 'effective',
    icon: generateSolidColorIcon('#033663', '16'),
  },
  {
    text: 'Solo perdidas',
    value: 'missed',
    icon: generateSolidColorIcon('#EECA86', '16'),
  },
  {
    text: 'Solo buzón de voz',
    value: 'voicemail',
    icon: generateSolidColorIcon('#E9AB11', '16'),
  },
];

const CallTypeSelect: React.FC<CallTypeSelectProps> = ({
  selectedCallType,
  onCallTypeChange,
}) => (
  <Select
    data={callTypeOptions}
    // TODO: check when implementing i18n
    label="Filtrar por tipo"
    value={selectedCallType}
    onValueChange={(selectedData: SelectData | SelectData[]) => {
      if (Array.isArray(selectedData)) return;
      onCallTypeChange(selectedData.value as CallType);
    }}
    search={false}
    containerClassName="max-w-[16rem]"
  />
);

export default CallTypeSelect;
