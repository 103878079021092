// src/components/atoms/buttons/ModalButton.tsx
import React, { useState } from 'react';
import { IconButton } from '@material-tailwind/react';
import Modal from 'components/atoms/modals/modal';

interface ModalButtonProps {
  children: (enabled: boolean) => JSX.Element;
  copyableContent?: string;
  icon: JSX.Element;
  title?: string;
  visible?: boolean;
  className?: string;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  children,
  copyableContent,
  icon,
  title = '',
  visible = true,
  className = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        variant="text"
        className={`mx-1 ${className} ${!visible ? 'invisible' : ''}`}
        disabled={!visible}
      >
        {icon}
      </IconButton>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        copyableContent={copyableContent}
      >
        {children(isModalOpen)}
      </Modal>
    </>
  );
};

export default ModalButton;
