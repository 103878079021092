import { Typography } from '@material-tailwind/react';
import Table, { TableRowProps } from 'components/molecules/tables/table';
import ViewContainer from 'components/atoms/view-container';
import React from 'react';
import { ContactView, User } from 'src/api/generated';
import PhoneNumberItem from 'components/molecules/phone/phone-number-item';
import Finder from 'components/molecules/finder';
import ViewFilter from 'components/atoms/view-filter';
import AgentSelect from 'components/molecules/agent-select';
import { useAgents } from 'hooks/api/users';
import { useContactsQueryParams } from 'hooks/contacts';
import { useCurrentUser } from 'hooks/users';
import { formatTimeDistance } from 'src/utils/formatting/date';
import ContactQualiChip from 'components/molecules/analysis/contact-quali-chip';
import Paginator from 'components/molecules/tables/paginator';
import SortingHeader from 'components/molecules/tables/sorting-header';
import { parseDate } from 'src/utils/date';
import { formatFullName } from 'src/utils/formatting/name';
import { findUser } from 'src/utils/users';
import { useContactViews } from 'hooks/api/contacts/contact-views';

const DEFAULT_SORT_FIELD = 'lastCallDate';
const DEFAULT_SORT_ORDER = 'desc';
const DEFAULT_PAGE_SIZE = 10;

const ContactsView: React.FC = () => {
  const { users, loading: usersLoading, error: usersError } = useAgents();
  const { currentUser } = useCurrentUser();

  const {
    queryParams,
    selectedAgentId,
    sendAgentSelection,
    sendSearch,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
  } = useContactsQueryParams(
    users,
    currentUser,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_ORDER,
    DEFAULT_PAGE_SIZE
  );
  const {
    contactViews,
    totalPages,
    loading: contactViewsLoading,
    error: contactViewsError,
  } = useContactViews(queryParams);

  const rows: TableRowProps[] = contactViews.map((contactView) =>
    buildRow(contactView, users)
  );

  const loading = contactViewsLoading || usersLoading;
  const error = contactViewsError || usersError;

  // TODO: check when implementing i18n
  const columns = [
    {
      header: 'Tiempo desde última llamada',
      accessor: 'lastCallDate',
    },
    {
      header: 'Nombre',
      accessor: 'firstName',
    },
    {
      header: 'Teléfono',
      accessor: 'phone',
    },
    {
      header: 'Agente',
      accessor: 'userId',
    },
    {
      header: 'Llamadas Efectivas',
      accessor: 'completedCallsCount',
    },
    {
      header: 'Llamadas Perdidas',
      accessor: 'missedCallsCount',
    },
    {
      header: 'Calificación prospecto (0-10)',
      accessor: 'maxQualiScore',
    },
  ];

  const headerElement = (
    <ViewFilter>
      <AgentSelect
        agents={users}
        selectedAgent={selectedAgentId}
        onAgentSelectionChange={sendAgentSelection}
      />
      <Finder onSearch={sendSearch} />
    </ViewFilter>
  );

  const sortableColumns = columns.map((column) => ({
    ...column,
    header: (
      <SortingHeader
        header={column.header}
        accessor={column.accessor}
        sortField={sortField}
        onSetSortField={selectSortField}
        order={sortOrder}
        onSetOrder={setSortOrder}
      />
    ),
  }));

  return (
    <ViewContainer
      // TODO: check when implementing i18n
      title="Prospectos"
      headerElement={headerElement}
      loading={loading}
      error={error}
    >
      <Table columns={sortableColumns} data={rows} />
      <Paginator
        currentPage={page}
        totalPages={totalPages}
        onPageChange={setPage}
      />
    </ViewContainer>
  );
};

const buildRow = (contactView: ContactView, users: User[]): TableRowProps => {
  const lastCallDate = parseDate(contactView.lastCallDate);
  const contactName = formatFullName(
    contactView.firstName,
    contactView.lastName
  );
  const agent = findUser(contactView.userId, users);
  const agentName = formatFullName(agent?.firstName, agent?.lastName);
  const contactId = contactView.id;
  const contactPhone = contactView.phone;
  const missedCalls = contactView.missedCallsCount;
  const completedCalls = contactView.completedCallsCount;
  const maxQualiScore = contactView.maxQualiScore;
  const timeSinceLastCall = lastCallDate
    ? new Date().getTime() - lastCallDate.getTime()
    : 0;

  const lastCallTimeElement = () => {
    const lastCallTime = lastCallDate ? formatTimeDistance(lastCallDate) : '';
    return (
      <Typography variant="small" className="font-normal">
        {lastCallTime}
      </Typography>
    );
  };
  const phoneNumberElement = () => {
    return <PhoneNumberItem phoneNumber={contactPhone} />;
  };
  const missedCallsElement = () => (
    <Typography variant="small" className="font-normal text-red-600">
      {missedCalls}
    </Typography>
  );

  const tableRow: TableRowProps = {
    key: contactId.toString(),
    row: [
      {
        accesor: 'lastCallDate',
        value: timeSinceLastCall,
        element: lastCallTimeElement(),
      },
      { accesor: 'firstName', value: contactName || '-' },
      {
        accesor: 'phone',
        value: contactPhone,
        element: phoneNumberElement(),
      },
      {
        accesor: 'userId',
        value: agentName || '-',
      },
      { accesor: 'completedCallsCount', value: completedCalls },
      {
        accesor: 'missedCallsCount',
        value: missedCalls,
        element: missedCallsElement(),
      },
      {
        accesor: 'maxQualiScore',
        value: maxQualiScore ?? -1,
        element: <ContactQualiChip value={maxQualiScore} />,
      },
    ],
  };
  return tableRow;
};

export default ContactsView;
