// src/hooks/api/contacts/contacts.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from '../api';
import {
  ApiQueryParams,
  Contact,
  ContactsService,
  ContactWithCalls,
  PaginatedData_ContactWithCalls_,
} from 'src/api/generated';

interface ContactsResponse extends ApiResponse {
  contacts: Contact[];
}

export const useContacts = (
  queryParams: ApiQueryParams = {}
): ContactsResponse => {
  const fetchContacts = async (): Promise<Contact[]> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await ContactsService.getContacts(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['contacts', queryParams],
    queryFn: fetchContacts,
  });

  return {
    loading,
    error,
    contacts: data || [],
    refetch,
  };
};

interface ContactsWithCallsResponse extends ApiResponse {
  contacts: ContactWithCalls[];
  totalPages: number;
}

export const useContactsWithCalls = (
  queryParams: ApiQueryParams = {},
  enabled = true
): ContactsWithCallsResponse => {
  const fetchContactsWithCalls =
    async (): Promise<PaginatedData_ContactWithCalls_> => {
      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

      return await ContactsService.getContactsWithCalls(
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['contacts-with-calls', queryParams],
    queryFn: fetchContactsWithCalls,
    enabled,
  });
  const contacts = useMemoizedArrayData<ContactWithCalls[]>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    contacts,
    totalPages,
    refetch,
  };
};

export const useOpportunitiesContacts = (
  queryParams: ApiQueryParams = {}
): ContactsWithCallsResponse => {
  const fetchOpportunitiesContacts =
    async (): Promise<PaginatedData_ContactWithCalls_> => {
      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

      return await ContactsService.getOpportunitiesContacts(
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['opportunities-contacts', queryParams],
    queryFn: fetchOpportunitiesContacts,
  });
  const contacts = useMemoizedArrayData<ContactWithCalls[]>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    contacts,
    totalPages,
    refetch,
  };
};
