// src/hooks/api/contacts/contact-views.ts

import { useQuery } from '@tanstack/react-query';
import {
  ApiQueryParams,
  ContactsService,
  ContactView,
  PaginatedData_ContactView_,
} from 'src/api/generated';
import { ApiResponse, useMemoizedArrayData } from '../api';

interface ContactViewsResponse extends ApiResponse {
  contactViews: ContactView[];
  totalPages: number;
}

export const useContactViews = (
  queryParams: ApiQueryParams = {}
): ContactViewsResponse => {
  const fetchContactViews = async (): Promise<PaginatedData_ContactView_> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await ContactsService.getContactViews(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['contacts/views', queryParams],
    queryFn: fetchContactViews,
  });
  const contactViews = useMemoizedArrayData<ContactView[]>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    contactViews,
    totalPages,
    refetch,
  };
};
