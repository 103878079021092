import React, { useState } from 'react';
import InlineTextInput from 'components/atoms/forms/inline-text-input';
import InlineSaveButton from 'components/atoms/buttons/inline-save-button';
import InlineCancelButton from 'components/atoms/buttons/inline-cancel-button';
import InlineEditButton from 'components/atoms/buttons/inline-edit-button';
import { Spinner } from '@material-tailwind/react';

interface EditableTableCellTextInputProps {
  content: React.ReactNode;
  onSave: (newValue: string) => Promise<boolean>;
  loading?: boolean;
}

const EditableTableCellTextInput: React.FC<EditableTableCellTextInputProps> = ({
  content,
  onSave,
  loading,
}) => {
  const [editMode, setEditMode] = useState(false);
  const textContent = typeof content === 'string' ? content : '';
  const [inputValue, setInputValue] = useState(textContent);
  const [originalValue] = useState(textContent);

  const handleSave = async () => {
    const success: boolean = await onSave(inputValue);
    if (success) {
      setEditMode(false);
    }
  };

  const handleCancel = () => {
    setInputValue(originalValue);
    setEditMode(false);
  };

  return (
    <div className="flex items-center space-x-1">
      {editMode ? (
        <>
          <InlineTextInput
            value={inputValue}
            onChange={setInputValue}
            className="flex-1"
            // TODO: check when implementing i18n
            placeholder="Teléfono Individual"
          />
          {loading ? (
            <Spinner className="h-4 w-4" />
          ) : (
            <>
              <InlineSaveButton onClick={handleSave} />
              <InlineCancelButton onClick={handleCancel} />
            </>
          )}
        </>
      ) : (
        <>
          <div className="flex-1">{content}</div>
          <InlineEditButton onClick={() => setEditMode(true)} />
        </>
      )}
    </div>
  );
};

export default EditableTableCellTextInput;
