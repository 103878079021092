/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Condition } from './Condition';

export type ToolMessageStart = {
    /**
     * This is an optional array of conditions that the tool call arguments must meet in order for this message to be triggered.
     */
    conditions?: Array<Condition>;
    /**
     * This is the content that the assistant says when this message is triggered.
     */
    content: string;
    /**
     * This message is triggered when the tool call starts.
     *
     * This message is never triggered for async tools.
     *
     * If this message is not provided, one of the default filler messages "Hold on a sec", "One moment", "Just a sec", "Give me a moment" or "This'll just take a sec" will be used.
     */
    type: ToolMessageStart.type;
};

export namespace ToolMessageStart {

    /**
     * This message is triggered when the tool call starts.
     *
     * This message is never triggered for async tools.
     *
     * If this message is not provided, one of the default filler messages "Hold on a sec", "One moment", "Just a sec", "Give me a moment" or "This'll just take a sec" will be used.
     */
    export enum type {
        REQUEST_START = 'request-start',
    }


}

