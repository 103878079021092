import DiallerButton from '../dialler-button';

interface DeleteButtonProps {
  onClick: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => {
  const customClasses = `text-white bg-red-400 hover:bg-red-700 border-red-700`;

  return (
    <DiallerButton onClick={onClick} customClasses={customClasses}>
      ←
    </DiallerButton>
  );
};

export default DeleteButton;
