/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OpenAIMessage } from './OpenAIMessage';
import type { Record_string_any_ } from './Record_string_any_';

export type ConversationUpdateServerMessage = {
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record_string_any_;
    /**
     * This is the main `call` object of the call.
     */
    call: Record_string_any_;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record_string_any_;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record_string_any_;
    /**
     * This is the most up-to-date conversation history at the time the message is sent.
     */
    messagesOpenAIFormatted: Array<OpenAIMessage>;
    /**
     * This is the type of the message. "conversation-update" is sent when an update is committed to the conversation history.
     */
    type: ConversationUpdateServerMessage.type;
};

export namespace ConversationUpdateServerMessage {

    /**
     * This is the type of the message. "conversation-update" is sent when an update is committed to the conversation history.
     */
    export enum type {
        CONVERSATION_UPDATE = 'conversation-update',
    }


}

