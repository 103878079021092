import Button from 'components/atoms/buttons/button';
import React, { useEffect, useRef, useState } from 'react';
// Suponiendo que los componentes de los íconos estén en un directorio llamado 'Icons'
import Icons from 'components/atoms/icons';

interface OnCallProps {
  onHangUp: () => void;
  onMute: (isMuted: boolean) => void;
  phoneNumber: string;
}

const OnCall: React.FC<OnCallProps> = ({ onHangUp, onMute, phoneNumber }) => {
  const [callDuration, setCallDuration] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [isMuted, setIsMuted] = useState(false);

  const handleMuteToggle = () => {
    setIsMuted((prevMuted) => !prevMuted);

    onMute(!isMuted);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCallDuration((prevDuration) => prevDuration + 1);
    }, 1000);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const getFormattedDuration = () => {
    const minutes = Math.floor(callDuration / 60);
    const seconds = callDuration % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div className="px-4 text-blue-600 font-bold flex flex-col h-full justify-center items-center">
      <div className="text-center mb-1">
        <p className="text-xs">{getFormattedDuration()}</p>
        <h2 className="text-md">{phoneNumber}</h2>

        {/* Contenedor con altura fija para la etiqueta */}
        <div className="flex h-4 justify-center">
          {isMuted && (
            <span className="bg-red-500 text-white rounded px-1 text-[10px]">
              {/* TODO: check when implementing i18n */}
              micrófono silenciado
            </span>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center space-x-4 w-full justify-center">
        <Button
          onClick={handleMuteToggle}
          customClasses={`mute-button rounded text-white !py-1 ${
            isMuted
              ? '!bg-green-500 hover:!bg-green-600'
              : '!bg-gray-500 hover:!bg-gray-600'
          }`}
        >
          {isMuted ? <Icons.MicWhite /> : <Icons.MicOff />}
        </Button>

        <Button
          onClick={onHangUp}
          customClasses="hang-up-button !bg-red-500 hover:!bg-red-900 rounded !py-0.5"
        >
          {/* TODO: check when implementing i18n */}
          Terminar llamada
        </Button>
      </div>
    </div>
  );
};

export default OnCall;
