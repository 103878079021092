/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CallStatus } from './CallStatus';

export type CreateTwilioRedirectCallConnectionParams = {
    AccountSid: string;
    AddOns?: string;
    ApiVersion: string;
    ApplicationSid?: string;
    CallSid: string;
    ParentCallSid?: string;
    CallStatus: CallStatus;
    CallToken?: string;
    Called: string;
    CalledCity?: string;
    CalledCountry?: string;
    CalledState?: string;
    CalledZip?: string;
    CalledVia?: string;
    Caller: string;
    CallerCity?: string;
    CallerCountry?: string;
    CallerState?: string;
    CallerZip?: string;
    CallerName?: string;
    Direction: CreateTwilioRedirectCallConnectionParams.Direction;
    From: string;
    FromCity?: string;
    FromCountry?: string;
    FromState?: string;
    FromZip?: string;
    To: string;
    ToCity?: string;
    ToCountry?: string;
    ToState?: string;
    ToZip?: string;
    ForwardedFrom?: string;
    Duration?: number;
    CallDuration?: number;
    SipResponseCode?: string;
    RecordingUrl?: string;
    RecordingSid?: string;
    RecordingDuration?: number;
    Timestamp?: string;
    CallbackSource?: string;
    SequenceNumber?: string;
    ErrorCode?: string;
    ErrorMessage?: string;
    'SipHeader_X-Asterisk-HangupCause'?: string;
    'SipHeader_X-Asterisk-HangupCauseCode'?: string;
    StirStatus?: string;
    StirVerstat?: string;
    DialBridged: boolean;
    DialCallSid: string;
    DialCallStatus: CreateTwilioRedirectCallConnectionParams.DialCallStatus;
    DialCallDuration?: number;
};

export namespace CreateTwilioRedirectCallConnectionParams {

    export enum Direction {
        INBOUND = 'inbound',
        OUTBOUND_API = 'outbound-api',
        OUTBOUND_DIAL = 'outbound-dial',
    }

    export enum DialCallStatus {
        COMPLETED = 'completed',
        ANSWERED = 'answered',
        BUSY = 'busy',
        NO_ANSWER = 'no-answer',
        FAILED = 'failed',
        CANCELED = 'canceled',
    }


}

