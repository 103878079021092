/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Condition = {
    /**
     * This is the operator you want to use to compare the parameter and value.
     */
    operator: Condition.operator;
    /**
     * This is the value you want to compare against the parameter.
     */
    value: string;
    /**
     * This is the name of the parameter that you want to check.
     */
    param: string;
};

export namespace Condition {

    /**
     * This is the operator you want to use to compare the parameter and value.
     */
    export enum operator {
        EQ = 'eq',
        NEQ = 'neq',
        GT = 'gt',
        GTE = 'gte',
        LT = 'lt',
        LTE = 'lte',
    }


}

