import { Spinner, Typography } from '@material-tailwind/react';

const InfoLoading: React.FC = () => {
  return (
    <div className="flex flex-row gap-4 items-center justify-center min-w-[300px] p-6">
      {/* TODO: check when implementing i18n */}
      <Typography className="text-xl text-blue-400">Cargando</Typography>
      <Spinner color="blue" />
    </div>
  );
};

export default InfoLoading;
