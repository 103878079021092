import { Locale, format, formatDistanceToNowStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import { parseDate } from '../date';

export const formatTime = (
  isoString: string,
  formatType: 'HH:MM' | 'HH:MM:SS' = 'HH:MM'
): string => {
  const date = new Date(isoString);
  const timeFormat = formatType === 'HH:MM:SS' ? 'HH:mm:ss' : 'HH:mm';

  const formattedDate = format(date, timeFormat);
  return formattedDate;
};

export const formatDate = (
  isoString: string,
  custom: 'DD-MM' | 'DD-MM-YYYY' | null = null
) => {
  const date = parseDate(isoString);
  if (!date) return '';

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const time = formatTime(isoString);
  if (custom) {
    if (custom === 'DD-MM') return `${day}/${month}`;
    if (custom === 'DD-MM-YYYY') return `${day}-${month}-${year}`;
  }
  return `${day}-${month}-${year} ${time}`;
};

type LocaleMapping = {
  [key: string]: Locale;
};

/**
 * Formats the time difference between a given date and the current date in a human-readable format.
 *
 * @param date - The date to compare with the current date.
 * @param localeCode - The locale code (e.g., 'en-US' for American English, 'es' for Spanish).
 * @param addSuffix - Whether to add a suffix to the formatted time difference (default is true).
 * @returns The formatted time difference as a string.
 */
export function formatTimeDistance(
  date: Date,
  addSuffix: boolean = true,
  localeCode: string = 'es'
): string {
  const locales: LocaleMapping = {
    es,
    // Add more locales here as needed
  };

  const locale = locales[localeCode] || es;
  return formatDistanceToNowStrict(date, { addSuffix, locale });
}
