import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

interface ChatProps {
  providerName: 'tawk';
  propertyId: string;
  widgetId: string;
}

const Chat: React.FC<ChatProps> = ({ providerName, propertyId, widgetId }) => {
  return providerName === 'tawk' ? (
    <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} />
  ) : null;
};

export default Chat;
