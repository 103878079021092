import { useNetworkState } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import ErrorReporter from 'src/monitoring/errorReporter';
import { useCurrentUser } from './users';

const RTT_THRESHOLD = 250;
const BANDWIDTH_THRESHOLD = 1.5;

export const useNetworkIndicator = () => {
  const network = useNetworkState();
  const { currentUser } = useCurrentUser();

  const hasBadConnection =
    network.effectiveType && network.effectiveType !== '4g';
  const hasHighLatency = network.rtt && network.rtt > RTT_THRESHOLD;
  const hasLowBandwidth =
    network.downlink && network.downlink < BANDWIDTH_THRESHOLD;
  const isOnline = network.online;

  useEffect(() => {
    if (hasBadConnection || hasHighLatency || hasLowBandwidth || !isOnline) {
      const networkString =
        `Network: ` +
        `downlink=${network.downlink} ` +
        `downlinkMax=${network.downlinkMax} ` +
        `effectiveType=${network.effectiveType} ` +
        `online=${network.online} ` +
        `rtt=${network.rtt} ` +
        `saveData=${network.saveData} ` +
        `type=${network.type}`;

      // TODO: standardize message to be grouped in Sentry. Use variables/breadcrumbs for more info.
      ErrorReporter.sendMessage(
        `User with network issues. ${networkString} Tenant: ${currentUser?.tenantId}. User: ${currentUser?.email}`,
        'log'
      );
    }
  }, [
    currentUser?.email,
    currentUser?.tenantId,
    hasBadConnection,
    hasHighLatency,
    hasLowBandwidth,
    isOnline,
    network,
  ]);

  return {
    isOnline,
    hasHighLatency,
    hasLowBandwidth,
    hasBadConnection,
    network,
  };
};
