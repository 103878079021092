/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Phone } from '../models/Phone';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PhonesService {

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns Phone Ok
     * @throws ApiError
     */
    public static getPhones(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<Array<Phone>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/phones',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @returns Phone Ok
     * @throws ApiError
     */
    public static getAvailablePhones(): CancelablePromise<Array<Phone>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/phones/available',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @returns Phone Ok
     * @throws ApiError
     */
    public static getAssignedPhone(): CancelablePromise<Phone> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/phones/available/assigned',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
