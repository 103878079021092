import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const useRefreshAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const refresh = useCallback(async () => {
    const cacheMode: 'on' | 'off' = 'off';
    await getAccessTokenSilently({ cacheMode });
  }, [getAccessTokenSilently]);

  return { refresh };
};
