// src/hooks/api/calls.ts

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from '../api';
import {
  ApiQueryParams,
  CallWithAnalysisAndUser,
  Call,
  PaginatedData_CallWithAnalysisAndUser_,
  CallsService,
} from 'src/api/generated';
import { MINUTE_IN_MS } from 'src/utils/date';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';

interface CallsResponse extends ApiResponse {
  calls: CallWithAnalysisAndUser[];
  totalPages: number;
}

export const useCalls = (queryParams: ApiQueryParams = {}): CallsResponse => {
  const queryClient = useQueryClient();

  const fetchCalls =
    async (): Promise<PaginatedData_CallWithAnalysisAndUser_> => {
      queryClient.invalidateQueries({ queryKey: ['contacts-with-calls'] });

      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;
      return await CallsService.getCalls(
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['calls', queryParams],
    queryFn: fetchCalls,
    // TODO: refetch only on specific error. Create hook from hook/api retry functions
    refetchInterval: MINUTE_IN_MS * 0.5,
  });
  const calls = useMemoizedArrayData<CallWithAnalysisAndUser[]>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    calls,
    totalPages,
    refetch,
  };
};

interface FeaturedCallsResponse extends ApiResponse {
  calls: Call[];
}

export const useFeaturedCalls = (
  startDate: string,
  endDate: string,
  filters: Record<string, FilterParam> = {},
  pageSize: number = 10
): FeaturedCallsResponse => {
  const fetchCalls = async (): Promise<Call[]> => {
    const filterBy = generateFilterQueryString(filters);
    return await CallsService.getFeaturedCalls(
      startDate,
      endDate,
      filterBy,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['calls/featured', { startDate, endDate, filters, pageSize }],
    queryFn: fetchCalls,
    staleTime: MINUTE_IN_MS * 5,
  });

  return {
    loading,
    error,
    calls: data || [],
    refetch,
  };
};
