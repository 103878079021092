import OnCall from 'components/molecules/phone/on-call';
import STATES from 'states/index';
import IncomingCall from 'components/molecules/phone/incoming-call';
import Display from 'components/atoms/phone/display';
import InfoLoading from 'components/atoms/info-loading';
import InfoError from 'components/atoms/info-error';
import { useMatchContact } from 'hooks/phones/phones';
import { usePhoneContext } from 'src/contexts/phone-context';
import { formatPhoneNumber } from 'src/utils/formatting/phone';
import IconCallButton from 'components/atoms/phone/icon-call-button';
import { CallProvider } from 'hooks/phones/types';
import { useBooleanSetting } from 'hooks/settings/settings';
import { SettingName } from 'src/api/generated';
import ContactDisplay from 'components/molecules/phone/contact-display';
import { useUpdateSetting } from 'hooks/api/settings';
import { useAlert } from 'src/contexts/alert-context';

interface CompactPhoneProps {
  callProvider: CallProvider;
}

const CompactPhone: React.FC<CompactPhoneProps> = ({
  callProvider: {
    callActions,
    loading: callProviderLoading,
    error: callProviderError,
    callerNumber,
    onCallNumber,
    phoneConfigActions,
  },
}) => {
  const {
    setting: callWithoutInternetChoose,
    loading: withoutInternetChooseSettingLoading,
    error: withoutInternetChooseSettingError,
  } = useBooleanSetting(SettingName.CALL_WITHOUT_INTERNET_CHOOSE);
  const {
    setting: callingWithoutInternet,
    loading: withoutInternetSettingLoading,
    error: withoutInternetSettingError,
  } = useBooleanSetting(SettingName.CALL_WITHOUT_INTERNET);

  const { showAlert } = useAlert();
  // TODO: check when implementing i18n
  const handleSettingUpdateError = () => {
    showAlert(
      `Ocurrió un error cambiando el modo del teléfono. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const { updateSetting, loading: updateSettingLoading } = useUpdateSetting(
    undefined,
    handleSettingUpdateError
  );

  const {
    phoneState,
    formattedPhoneNumber,
    standardizedPhoneNumber,
    validNumberSelected,
    initialNumber,
    setPhoneNumber,
    resetPhoneNumber,
  } = usePhoneContext();
  const displayNumber = callerNumber || standardizedPhoneNumber;
  const { contact, loading: matchContactloading } =
    useMatchContact(displayNumber);
  const formattedCallerNumber = formatPhoneNumber(callerNumber);

  const loading =
    callProviderLoading ||
    withoutInternetSettingLoading ||
    withoutInternetChooseSettingLoading ||
    updateSettingLoading;
  const error =
    callProviderError ||
    withoutInternetSettingError ||
    withoutInternetChooseSettingError;

  const containerClasses = 'flex flex-col items-center w-[298px] h-[370px]';
  if (loading || phoneState === STATES.PHONE.REGISTERING) {
    return (
      <div className={containerClasses}>
        <InfoLoading />
      </div>
    );
  }

  if (error) {
    return (
      <div className={containerClasses}>
        <InfoError error={error as Error} />
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      <div className="flex flex-col py-1 h-[100px] justify-center">
        {phoneState === STATES.PHONE.READY && (
          <div className="flex gap-2 p-2">
            <Display
              value={formattedPhoneNumber}
              initialValue={initialNumber}
              onChange={setPhoneNumber}
              onClear={resetPhoneNumber}
            />
            <IconCallButton
              startCall={callActions.startCall}
              disabled={!validNumberSelected}
              callWithoutInternetChoose={callWithoutInternetChoose}
              callingWithoutInternet={callingWithoutInternet}
              updateSetting={(useInternet) =>
                updateSetting({
                  name: SettingName.CALL_WITHOUT_INTERNET,
                  value: useInternet ? 'active' : 'inactive',
                })
              }
            />
          </div>
        )}
        {phoneState === STATES.PHONE.INCOMING && (
          <IncomingCall
            onAccept={callActions.acceptCall}
            onReject={callActions.rejectCall}
            phoneNumber={formattedCallerNumber}
          />
        )}
        {phoneState === STATES.PHONE.ON_CALL && (
          <OnCall
            onHangUp={callActions.endCall}
            onMute={callActions.muteCall}
            phoneNumber={onCallNumber}
          />
        )}
      </div>
      <ContactDisplay
        contact={contact}
        loading={matchContactloading}
        phoneConfigActions={phoneConfigActions}
        callingWithoutInternet={callingWithoutInternet}
      />
    </div>
  );
};

export default CompactPhone;
