import { CallWarning } from 'hooks/phones/types';
import ClickableTooltipChip from '../clickable-tooltip-chip';
import { useNetworkIndicator } from 'hooks/network';

interface NetworkIndicatorProps {
  warnings?: CallWarning[];
}

const NETWORK_BEST_PRACTICES_URL =
  'https://docs.google.com/document/d/1UupxN38Me3IeD09oJq4hGslZVjUDmdgoDrcwgiCKK-4/edit?usp=sharing';

const NetworkIndicator: React.FC<NetworkIndicatorProps> = ({
  warnings = [],
}: NetworkIndicatorProps) => {
  const { isOnline, hasHighLatency, hasLowBandwidth, hasBadConnection } =
    useNetworkIndicator();
  const hasCallWarnings = warnings.length > 0;

  if (!isOnline) {
    return (
      <ClickableTooltipChip
        chipContent="Sin conexión"
        chipColor="red"
        chipClasses="bg-red-900"
      />
    );
  }

  if (hasCallWarnings) {
    // TODO: show a different message for audio warnings
    return (
      <ClickableTooltipChip
        chipContent="Llamada afectada"
        tooltipContent={
          'Tu llamada está siendo afectada por mala conexión a internet. ' +
          'Haz click para mejorar tu conexión.'
        }
        chipColor="yellow"
        chipClasses="bg-yellow-900"
        href={NETWORK_BEST_PRACTICES_URL}
      />
    );
  }

  if (hasHighLatency || hasBadConnection || hasLowBandwidth) {
    return (
      <ClickableTooltipChip
        chipContent="Conexión lenta"
        tooltipContent={
          'Podrías tener problemas en la llamada por mala conexión a internet. ' +
          'Haz click para mejorar tu conexión.'
        }
        chipColor="yellow"
        chipClasses="bg-yellow-900"
        href={NETWORK_BEST_PRACTICES_URL}
      />
    );
  }

  return null;
};

export default NetworkIndicator;
