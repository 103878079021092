import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { User } from 'src/api/generated';
import { useUsers } from './api/users';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';

export const useCurrentUser = (): { currentUser: User | null } => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { user } = useAuth0();

  const email: string | undefined = user?.email;
  const filters: Record<string, FilterParam> = {};
  if (email) {
    filters.email = { value: email };
  }
  const filterBy = generateFilterQueryString(filters);
  const pageSize = 1;
  const page = 1;
  const { users } = useUsers({ filterBy, page, pageSize });

  useEffect(() => {
    if (!users) return;

    setCurrentUser(users[0]);
  }, [users]);

  return { currentUser };
};

export const useFilterAgent = (users: User[], currentUser: User | null) => {
  const [selectedAgentId, setSelectedAgentId] = useState<number | null>(null);

  useEffect(() => {
    const exists =
      currentUser && users && users.some((user) => user.id === currentUser.id);

    if (currentUser && exists) {
      setSelectedAgentId(currentUser.id);
    }
  }, [currentUser, users]);

  return { selectedAgentId, sendAgentSelection: setSelectedAgentId };
};
