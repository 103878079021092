// src/hooks/calls.ts

import { ApiQueryParams, User } from 'src/api/generated';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { useSearch } from './search';
import { useFilterAgent } from './users';
import { SortOrder } from 'components/molecules/tables/sorting-header';
import { useDataFilteringQueryParams } from './dataFiltering';

export const useCallsQueryParams = (
  agentsInFilter: User[],
  currentUser: User | null,
  defaultSortField: string | null,
  defaultSortOrder: SortOrder,
  defaultPageSize: number | null
) => {
  const { selectedAgentId, sendAgentSelection } = useFilterAgent(
    agentsInFilter,
    currentUser
  );

  const filters: Record<string, FilterParam> = {};
  if (selectedAgentId) filters.userId = { value: selectedAgentId };

  const filterBy = generateFilterQueryString(filters);
  const { searchQueryParams, sendSearch } = useSearch();
  const {
    dataFilteringQueryParams,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  } = useDataFilteringQueryParams(
    defaultSortField,
    defaultSortOrder,
    defaultPageSize
  );

  const queryParams: ApiQueryParams = {
    filterBy,
    ...searchQueryParams,
    ...dataFilteringQueryParams,
  };

  return {
    queryParams,
    selectedAgentId,
    sendAgentSelection,
    sendSearch,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

// const filterCallsByPeriod = (
//   calls: CallWithAnalysisAndUser[],
//   period: 'daily' | 'weekly' | 'monthly',
//   startDate?: Date,
//   endDate?: Date
// ): CallWithAnalysisAndUser[] => {
//   const now = new Date();

//   return calls.filter((call) => {
//     if (!call.startTime) return false;

//     const callDate = new Date(call.startTime);
//     const isUserNameNotEmpty =
//       call.user?.firstName !== '' || call.user?.lastName !== '';

//     let isPeriodMatch = false;
//     switch (period) {
//       case 'daily':
//         isPeriodMatch = callDate.toDateString() === now.toDateString();
//         break;
//       case 'weekly':
//         isPeriodMatch = callDate >= getStartOfWeek(now) && callDate <= now;
//         break;
//       case 'monthly':
//         isPeriodMatch =
//           callDate >= (startDate ?? getStartOfMonth(now)) &&
//           callDate <= (endDate ?? now);
//         break;
//     }

//     return isPeriodMatch && isUserNameNotEmpty;
//   });
// };

// const sortCallsByScore = (
//   calls: CallWithAnalysisAndUser[],
//   minScore: number,
//   analysisValues: AnalysisValue[] = []
// ): CallWithAnalysisAndUser[] => {
//   const deprecatedValueToExtract = ['desempeno_vendedor', 'suma_puntuaciones'];

//   const filteredCalls = filterCallsByMinScore(
//     calls,
//     minScore,
//     analysisValues,
//     deprecatedValueToExtract
//   );
//   const sortedCalls = sortFeaturedCalls(
//     filteredCalls,
//     analysisValues,
//     deprecatedValueToExtract
//   );

//   return sortedCalls;
// };

// const filterCallsByMinScore = (
//   calls: CallWithAnalysisAndUser[],
//   minScore: number,
//   analysisValues: AnalysisValue[] = [],
//   deprecatedValueToExtract: string[]
// ) => {
//   const featuredCalls = calls.filter((call) => {
//     const callAnalysis = call?.recording?.transcription?.callAnalysis;

//     const isDeprecated = callAnalysis?.version !== null;
//     if (isDeprecated) {
//       const score: number =
//         extractValue(callAnalysis, deprecatedValueToExtract) || 0;
//       return score >= minScore;
//     }

//     const callAnalysisValues = getCallAnalysisValues(
//       analysisValues,
//       callAnalysis
//     );
//     const totalFeedbackValue = getTotalPerformanceValue(callAnalysisValues);
//     return totalFeedbackValue >= minScore;
//   });

//   return featuredCalls;
// };

// const sortFeaturedCalls = (
//   calls: CallWithAnalysisAndUser[],
//   analysisValues: AnalysisValue[] = [],
//   deprecatedValueToExtract: string[]
// ) => {
//   const sortedFeaturedCalls = calls.sort((callA, callB) => {
//     const callAnalysisA = callA?.recording?.transcription?.callAnalysis;
//     const callAnalysisB = callB?.recording?.transcription?.callAnalysis;

//     const isDeprecated = (callAnalysis?: CallAnalysis | null) =>
//       callAnalysis?.version !== null;

//     let scoreA = 0;
//     if (isDeprecated(callAnalysisA)) {
//       scoreA = extractValue(callAnalysisA, deprecatedValueToExtract) || 0;
//     } else {
//       const callAnalysisValuesA = getCallAnalysisValues(
//         analysisValues,
//         callAnalysisA
//       );
//       scoreA = getTotalPerformanceValue(callAnalysisValuesA);
//     }

//     let scoreB = 0;
//     if (isDeprecated(callAnalysisB)) {
//       scoreB = extractValue(callAnalysisB, deprecatedValueToExtract) || 0;
//     } else {
//       const callAnalysisValuesB = getCallAnalysisValues(
//         analysisValues,
//         callAnalysisB
//       );
//       scoreB = getTotalPerformanceValue(callAnalysisValuesB);
//     }

//     return scoreB - scoreA;
//   });

//   return sortedFeaturedCalls;
// };

// type FilterAndSortCallsParams = {
//   calls: CallWithAnalysisAndUser[];
//   analysisValues: AnalysisValue[];
//   period: 'daily' | 'weekly' | 'monthly';
//   size: number;
//   minScore: number;
//   startDate?: Date;
//   endDate?: Date;
// };

// const filterAndSortCalls = ({
//   calls,
//   analysisValues = [],
//   period,
//   size,
//   minScore,
//   startDate,
//   endDate,
// }: FilterAndSortCallsParams): CallWithAnalysisAndUser[] => {
//   const filteredCalls = filterCallsByPeriod(calls, period, startDate, endDate);
//   const sortedCalls = sortCallsByScore(filteredCalls, minScore, analysisValues);

//   return sortedCalls.slice(0, size);
// };

// export const usePeriodCalls = (selectedMonth: Date) => {
//   const { loading, error, calls } = useCalls();
//   const [dailyCalls, setDailyCalls] = useState<CallWithAnalysisAndUser[]>([]);
//   const [weeklyCalls, setWeeklyCalls] = useState<CallWithAnalysisAndUser[]>([]);
//   const [monthlyCalls, setMonthlyCalls] = useState<CallWithAnalysisAndUser[]>(
//     []
//   );
//   const { analysisValues } = useAnalysisValuesFromCalls(calls);

//   useEffect(() => {
//     const completedCalls = filterCompletedCalls(calls);
//     const firstDayOfMonth = new Date(
//       selectedMonth.getFullYear(),
//       selectedMonth.getMonth(),
//       1
//     );
//     const lastDayOfMonth = new Date(
//       selectedMonth.getFullYear(),
//       selectedMonth.getMonth() + 1,
//       0
//     );

//     setDailyCalls(
//       filterAndSortCalls({
//         calls: completedCalls,
//         analysisValues,
//         period: 'daily',
//         size: 5,
//         minScore: 25,
//       })
//     );
//     setWeeklyCalls(
//       filterAndSortCalls({
//         calls: completedCalls,
//         analysisValues,
//         period: 'weekly',
//         size: 5,
//         minScore: 25,
//       })
//     );
//     setMonthlyCalls(
//       filterAndSortCalls({
//         calls: completedCalls,
//         analysisValues,
//         period: 'monthly',
//         size: 10,
//         minScore: 25,
//         startDate: firstDayOfMonth,
//         endDate: new Date() > lastDayOfMonth ? lastDayOfMonth : new Date(),
//       })
//     );
//   }, [analysisValues, calls, selectedMonth]);

//   return { loading, error, dailyCalls, weeklyCalls, monthlyCalls, calls };
// };
