/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FunctionToolWithToolCall } from './FunctionToolWithToolCall';
import type { GhlToolWithToolCall } from './GhlToolWithToolCall';
import type { MakeToolWithToolCall } from './MakeToolWithToolCall';
import type { Record_string_any_ } from './Record_string_any_';
import type { ToolCall } from './ToolCall';

export type ToolCallsServerMessage = {
    /**
     * This is the list of tool calls that the model is requesting.
     */
    toolCallList: Array<ToolCall>;
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record_string_any_;
    /**
     * This is the main `call` object of the call.
     */
    call: Record_string_any_;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record_string_any_;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record_string_any_;
    /**
     * This is the list of tools calls that the model is requesting along with the original tool configuration.
     */
    toolWithToolCallList: Array<(FunctionToolWithToolCall | GhlToolWithToolCall | MakeToolWithToolCall)>;
    /**
     * This is the type of the message. "tool-calls" is sent to call a tool.
     */
    type?: ToolCallsServerMessage.type;
};

export namespace ToolCallsServerMessage {

    /**
     * This is the type of the message. "tool-calls" is sent to call a tool.
     */
    export enum type {
        TOOL_CALLS = 'tool-calls',
    }


}

