/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Record_string_any_ } from './Record_string_any_';

export type PhoneCallControlServerMessage = {
    /**
     * This is the phone number to forward to if the request is "forward".
     */
    forwardingPhoneNumber?: string;
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record_string_any_;
    /**
     * This is the main `call` object of the call.
     */
    call: Record_string_any_;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record_string_any_;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record_string_any_;
    /**
     * This is the request to control the phone call.
     */
    request: PhoneCallControlServerMessage.request;
    /**
     * This is the type of the message. "phone-call-control" is an advanced type of message.
     *
     * When it is requested in `assistant.serverMessages`, the hangup and forwarding responsibilities are delegated to your server. Vapi will no longer do the actual transfer and hangup.
     */
    type: PhoneCallControlServerMessage.type;
};

export namespace PhoneCallControlServerMessage {

    /**
     * This is the request to control the phone call.
     */
    export enum request {
        FORWARD = 'forward',
        HANG_UP = 'hang-up',
    }

    /**
     * This is the type of the message. "phone-call-control" is an advanced type of message.
     *
     * When it is requested in `assistant.serverMessages`, the hangup and forwarding responsibilities are delegated to your server. Vapi will no longer do the actual transfer and hangup.
     */
    export enum type {
        PHONE_CALL_CONTROL = 'phone-call-control',
    }


}

