import React from 'react';
import { IconButton } from '@material-tailwind/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface InlineSaveButtonProps {
  onClick: () => void;
  className?: string;
  iconClassName?: string;
}

const InlineSaveButton: React.FC<InlineSaveButtonProps> = ({
  onClick,
  className = '',
  iconClassName = '',
}) => {
  return (
    <IconButton
      onClick={onClick}
      variant="text"
      className={`w-8 h-8 ${className}`}
    >
      <CheckCircleIcon
        strokeWidth={2}
        className={`h-6 w-6 text-green-800 ${iconClassName}`}
      />
    </IconButton>
  );
};

export default InlineSaveButton;
