import DeleteButton from 'components/atoms/phone/delete-button';
import KeypadButton from 'components/atoms/phone/keypad-button';

interface DiallerProps {
  onDigitClick: (digit: string) => void;
  onDeleteClick: () => void;
}

const Dialler: React.FC<DiallerProps> = ({ onDigitClick, onDeleteClick }) => {
  const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '0'];

  return (
    <div className="flex flex-wrap justify-between w-56 my-4">
      {digits.map((digit) => (
        <KeypadButton
          key={digit}
          label={digit}
          onClick={() => onDigitClick(digit)}
        />
      ))}
      <DeleteButton key="delete" onClick={onDeleteClick} />
    </div>
  );
};

export default Dialler;
