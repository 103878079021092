import React, { ComponentProps } from 'react';

type ButtonProps = ComponentProps<'button'> & {
  customClasses?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  customClasses = '',
  ...rest
}) => {
  const classes = [
    'w-full',
    'whitespace-nowrap',
    'flex',
    'bg-blue-600',
    'hover:bg-blue-700',
    'text-white',
    'leading-6',
    'font-semibold',
    'py-3',
    'px-6',
    'border',
    'border-transparent',
    'rounded-xl',
    'transition-colors',
    'duration-200',
    'justify-center',
    customClasses,
  ].join(' ');

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

export default Button;
