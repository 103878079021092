/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Record_string_any_ } from './Record_string_any_';

export type TranscriptServerMessage = {
    /**
     * This is the transcript content.
     */
    transcript: string;
    /**
     * This is the type of the transcript.
     */
    transcriptType: TranscriptServerMessage.transcriptType;
    /**
     * This is the role for which the transcript is for.
     */
    role: TranscriptServerMessage.role;
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record_string_any_;
    /**
     * This is the main `call` object of the call.
     */
    call: Record_string_any_;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record_string_any_;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record_string_any_;
    /**
     * This is the type of the message. "transcript" is sent as transcriber outputs partial or final transcript.
     */
    type: TranscriptServerMessage.type;
};

export namespace TranscriptServerMessage {

    /**
     * This is the type of the transcript.
     */
    export enum transcriptType {
        PARTIAL = 'partial',
        FINAL = 'final',
    }

    /**
     * This is the role for which the transcript is for.
     */
    export enum role {
        ASSISTANT = 'assistant',
        USER = 'user',
    }

    /**
     * This is the type of the message. "transcript" is sent as transcriber outputs partial or final transcript.
     */
    export enum type {
        TRANSCRIPT = 'transcript',
    }


}

