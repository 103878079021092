// src/utils/timeoutManager.ts

type TimeoutCallback = () => void;

export class TimeoutManager {
  private static timeouts: Record<string, NodeJS.Timeout> = {};

  static setTimeout(
    key: string,
    callback: TimeoutCallback,
    delay: number
  ): void {
    this.clearTimeout(key);
    this.timeouts[key] = setTimeout(callback, delay);
  }

  static clearTimeout(key: string): void {
    if (this.timeouts[key]) {
      clearTimeout(this.timeouts[key]);
      delete this.timeouts[key];
    }
  }

  static clearAll(): void {
    Object.keys(this.timeouts).forEach((key) => {
      clearTimeout(this.timeouts[key]);
    });
    this.timeouts = {};
  }
}
