import Alert from 'components/molecules/alert';
import { AlertType } from 'components/molecules/alert/alert';
import TimedAlertModal from 'components/molecules/modals/timed-alert-modal';
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AlertContextType {
  showAlert: (message: string, type: AlertType) => void;
  showModalAlert: (
    message: string,
    title?: string,
    icon?: ReactNode,
    durationInMs?: number
  ) => void;
}

const AlertContext = createContext<AlertContextType>({
  showAlert: () => {},
  showModalAlert: () => {},
});

export const useAlert = () => useContext(AlertContext);

interface AlertProviderProps {
  children: ReactNode;
}

const ALERT_TIMEOUT_MS: number = 5000;

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<{
    message: string;
    type: AlertType;
  } | null>(null);

  const [modalAlert, setModalAlert] = useState<{
    message: string;
    durationInMs: number;
    title?: string;
    icon?: ReactNode;
  } | null>(null);

  const showAlert = (message: string, type: AlertType) => {
    setAlert({ message, type });
    setTimeout(() => setAlert(null), ALERT_TIMEOUT_MS);
  };

  const showModalAlert = (
    message: string,
    title?: string,
    icon?: ReactNode,
    durationInMs = ALERT_TIMEOUT_MS
  ) => {
    setModalAlert({ message, title, icon, durationInMs });
    setTimeout(() => setModalAlert(null), durationInMs);
  };

  return (
    <AlertContext.Provider value={{ showAlert, showModalAlert }}>
      {children}
      {alert && (
        <div
          className="fixed z-[60] bottom-0 left-0 right-0 z-100 w-screen flex  
        items-end justify-center"
        >
          <Alert message={alert.message} type={alert.type} />
        </div>
      )}
      {modalAlert && (
        <TimedAlertModal
          message={modalAlert.message}
          durationInMs={modalAlert.durationInMs}
          title={modalAlert.title}
          icon={modalAlert.icon}
          isOpen={!!modalAlert}
          onClose={() => setModalAlert(null)}
        />
      )}
    </AlertContext.Provider>
  );
};
