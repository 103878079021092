/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OpenAIMessage = {
    role: OpenAIMessage.role;
    content: string | null;
};

export namespace OpenAIMessage {

    export enum role {
        ASSISTANT = 'assistant',
        FUNCTION = 'function',
        USER = 'user',
        SYSTEM = 'system',
        TOOL = 'tool',
    }


}

