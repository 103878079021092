import React from 'react';
import { createRoot } from 'react-dom/client';
import './api/setupOpenAPI';
import 'tw-elements-react/dist/css/tw-elements-react.min.css';
import './index.css';
import ErrorReporter from './monitoring/errorReporter';
import { AppRouter } from './app-router';
import Providers from './contexts/context-providers';

const rootElement = document.getElementById('root')!;

ErrorReporter.initializeSentry();

if (!rootElement.innerHTML) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Providers>
        <AppRouter />
      </Providers>
    </React.StrictMode>
  );
}
