/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Condition } from './Condition';

export type ToolMessageFailed = {
    /**
     * This is an optional array of conditions that the tool call arguments must meet in order for this message to be triggered.
     */
    conditions?: Array<Condition>;
    /**
     * This is the content that the assistant says when this message is triggered.
     */
    content: string;
    /**
     * This message is triggered when the tool call fails.
     *
     * This message is never triggered for async tool calls.
     *
     * If this message is not provided, the model will be requested to respond.
     *
     * If this message is provided, only this message will be spoken and the model will not be requested to come up with a response. It's an exclusive OR.
     */
    type: ToolMessageFailed.type;
};

export namespace ToolMessageFailed {

    /**
     * This message is triggered when the tool call fails.
     *
     * This message is never triggered for async tool calls.
     *
     * If this message is not provided, the model will be requested to respond.
     *
     * If this message is provided, only this message will be spoken and the model will not be requested to come up with a response. It's an exclusive OR.
     */
    export enum type {
        REQUEST_FAILED = 'request-failed',
    }


}

