import { useState, useEffect } from 'react';
import { Device, Call, TwilioError } from '@twilio/voice-sdk';
import { useCreateCallSession } from '../api/calls/call-sessions';
import STATES from 'states/index';
import { useAuth0 } from '@auth0/auth0-react';
import { usePhoneContext } from 'src/contexts/phone-context';
import { useInvalidateCache } from '../cache';
import {
  CallProvider,
  CallWarning,
  PhoneCallActions,
  PhonePadActions,
  CallWarningData,
  StartCallParams,
} from './types';
import { useCurrentUser } from 'hooks/users';
import {
  TwilioPhoneCallActionsProps,
  TwilioPhonePadActionsProps,
} from './twilio.types';
import {
  formatPhoneNumber,
  standardizePhoneNumber,
} from 'src/utils/formatting/phone';
import ErrorReporter from 'src/monitoring/errorReporter';
import { SettingName } from 'src/api/generated';
import {
  useStringArraySetting,
  useStringSetting,
} from 'hooks/settings/settings';
import {
  CALL_QUALITY_BITRATES,
  CallQuality,
  DEFAULT_DEVICE_BITRATE,
} from 'src/types/phone-config';
import { usePhoneConfig } from './twilio-phone-config';
import { SEC_IN_MS } from 'src/utils/date';
import { TimeoutManager } from 'src/utils/timeoutManager';

// https://www.twilio.com/docs/api/errors/<error_code>
const TWILIO_TRANSPORT_ERROR_CODE: number = 31009;
const TWILIO_CONNECTION_ERROR_CODE: number = 31005;
const TWILIO_UNKNOWN_ERROR_CODE: number = 31000;
const TWILIO_ACCESS_TOKEN_EXPIRED: number = 20104;
const TWILIO_ACCESS_TOKEN_INVALID: number = 20101;

const ALERT_TIMEOUT = 20 * SEC_IN_MS;

const useSetCallHandlers = (
  call: Call | null,
  setCall: React.Dispatch<React.SetStateAction<Call | null>>
) => {
  const { setPhoneState } = usePhoneContext();
  const {
    invalidateCalls,
    invalidateContactsWithCalls,
    invalidateOpportunities,
  } = useInvalidateCache();

  useEffect(() => {
    if (call === null) return;

    const handleCallEnded = () => {
      setPhoneState(STATES.PHONE.READY);
      setCall(null);

      const delayInSeconds = 1;
      setTimeout(() => {
        invalidateCalls();
        invalidateContactsWithCalls();
        invalidateOpportunities();
      }, delayInSeconds * 1000);
    };

    call.on('disconnect', handleCallEnded);
    call.on('cancel', handleCallEnded);
    call.on('reject', handleCallEnded);
    call.on('error', handleCallEnded);

    return () => {
      const listeners = ['disconnect', 'cancel', 'reject', 'error'];
      call.removeAllListeners(...listeners);
    };
  }, [
    call,
    setCall,
    setPhoneState,
    invalidateCalls,
    invalidateContactsWithCalls,
    invalidateOpportunities,
  ]);
};

const useSetCallWarnings = (call: Call | null) => {
  const [warnings, setWarnings] = useState<CallWarning[]>([]);
  const [bufferedWarnings, setBufferedWarnings] = useState<CallWarning[]>([]);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (call === null) return;

    const isCallWarning = (warning: unknown): warning is CallWarning => {
      return warning !== undefined;
    };
    const handleCallWarning = (
      warningName: string,
      _warningData: CallWarningData
    ) => {
      if (!isCallWarning(warningName)) return;

      // TODO: only add relevant warnings
      // e.g. input/output audio warnings should be handled differently
      setWarnings((prevWarnings: CallWarning[]) => {
        if (prevWarnings.includes(warningName)) return prevWarnings;

        return [...prevWarnings, warningName];
      });
      setBufferedWarnings((prevBuffer: CallWarning[]) => {
        if (prevBuffer.includes(warningName)) return prevBuffer;

        return [...prevBuffer, warningName];
      });
    };
    const handleWarningCleared = (warningName: string) => {
      if (!isCallWarning(warningName)) return;

      setWarnings((prevWarnings: CallWarning[]) =>
        prevWarnings.filter(
          (warning) => warning !== (warningName as CallWarning)
        )
      );
    };

    call.on('warning', handleCallWarning);
    call.on('warning-cleared', handleWarningCleared);

    return () => {
      const listeners = ['warning', 'warning-cleared'];
      call.removeAllListeners(...listeners);
      setWarnings([]);
      setBufferedWarnings([]);
    };
  }, [call]);

  useEffect(() => {
    if (call === null) return;
    if (bufferedWarnings.length === 0) return;

    const callSid = call.parameters?.CallSid;
    const sendAlert = () => {
      const warningsList = bufferedWarnings.join('|');
      const callInfo = `CallSid:${callSid} To:${call.customParameters?.get('To')}`;
      ErrorReporter.sendMessage(
        `Call warnings:[${warningsList}] ${callInfo}` +
          `User:${currentUser?.email} Tenant:${currentUser?.tenantId}`,
        'log'
      );

      setBufferedWarnings([]);
    };
    const timeoutId = `alert-${callSid}`;
    TimeoutManager.setTimeout(timeoutId, sendAlert, ALERT_TIMEOUT);

    return () => {
      TimeoutManager.clearTimeout(timeoutId);
    };
  }, [bufferedWarnings, call, currentUser?.email, currentUser?.tenantId]);

  return { warnings };
};

const useDeviceToken = (): {
  token: string | null;
  refreshToken: () => void;
  loading: boolean;
  error: Error | null;
} => {
  const { user } = useAuth0();
  // TODO: check if security problem
  const identity = user?.email;
  const { callSession, createCallSession, error, loading } =
    useCreateCallSession();

  useEffect(() => {
    if (!identity) return;

    if (!callSession && !loading && !error) {
      createCallSession({ identity });
    }
  }, [identity, loading, error, callSession, createCallSession]);

  const token = callSession?.token || null;
  const refreshToken = identity
    ? () => createCallSession({ identity })
    : () => {};

  return { token, refreshToken, loading, error };
};

const useDeviceBitrate = (
  device: Device | null
): [number, (bitrate: number) => void] => {
  const { setting: settingCallQuality } = useStringSetting(
    SettingName.CALL_QUALITY
  );
  const [deviceBitrate, setDeviceBitrate] = useState<number>(
    DEFAULT_DEVICE_BITRATE
  );

  useEffect(() => {
    if (!settingCallQuality) return;

    const settingDeviceBitrate =
      CALL_QUALITY_BITRATES[settingCallQuality as CallQuality];
    if (settingDeviceBitrate) {
      setDeviceBitrate(settingDeviceBitrate);
    }
  }, [settingCallQuality]);

  useEffect(() => {
    if (
      deviceBitrate < CALL_QUALITY_BITRATES.LOW ||
      !device ||
      device.state === Device.State.Destroyed
    ) {
      return;
    }

    device.updateOptions({ maxAverageBitrate: deviceBitrate });
  }, [device, deviceBitrate]);

  return [deviceBitrate, setDeviceBitrate];
};

const useTwilioDevice = (): {
  device: Device | null;
  deviceBitrate: number;
  setDeviceBitrate: (maxAverageBitrate: number) => void;
  call: Call | null;
  setCall: React.Dispatch<React.SetStateAction<Call | null>>;
  loading: boolean;
  error: TwilioError.TwilioError | null;
  warnings: CallWarning[];
} => {
  const { setPhoneState } = usePhoneContext();
  const { token, refreshToken, error: errorToken } = useDeviceToken();
  const [error, setError] = useState<TwilioError.TwilioError | null>(null);
  const [device, setDevice] = useState<Device | null>(null);
  const [call, setCall] = useState<Call | null>(null);
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  const edge_locations = useStringArraySetting(
    SettingName.TWILIO_EDGE_LOCATIONS
  );
  const [deviceBitrate, setDeviceBitrate] = useDeviceBitrate(device);

  useSetCallHandlers(call, setCall);
  const { warnings } = useSetCallWarnings(call);

  useEffect(() => {
    if (!errorToken) return;

    const errorRetryTime = 4 * SEC_IN_MS;
    setTimeout(() => refreshToken(), errorRetryTime);
  }, [errorToken, refreshToken]);

  useEffect(() => {
    if (!token) return;

    const handleRegistering = () => setPhoneState(STATES.PHONE.REGISTERING);
    const handleRegistered = () => setPhoneState(STATES.PHONE.READY);
    const handleUnregistered = () => setPhoneState(STATES.PHONE.OFFLINE);
    const handleDestroyed = () => setPhoneState(STATES.PHONE.OFFLINE);
    const handleIncoming = (incomingCall: Call) => {
      setPhoneState(STATES.PHONE.INCOMING);
      setCall(incomingCall);
    };
    const handleTokenWillExpire = () => {
      refreshToken();
    };
    const handleError = (error: TwilioError.TwilioError) => {
      // TODO: create custom Error interface to abstract all errors
      const resetDevice = (): void => {
        setCurrentToken(null);
        refreshToken();
        setDevice(null);
        setCall(null);
      };

      // TODO: better error management
      if (
        error.code === TWILIO_TRANSPORT_ERROR_CODE ||
        error.code === TWILIO_CONNECTION_ERROR_CODE ||
        error.code === TWILIO_UNKNOWN_ERROR_CODE ||
        error.code === TWILIO_ACCESS_TOKEN_EXPIRED ||
        error.code === TWILIO_ACCESS_TOKEN_INVALID
      ) {
        resetDevice();
      } else {
        setPhoneState(STATES.PHONE.ERROR);
        setError(error);
      }
    };

    const setUpDevice = (device: Device) => {
      device.on('registering', handleRegistering);
      device.on('registered', handleRegistered);
      device.on('unregistered', handleUnregistered);
      device.on('destroyed', handleDestroyed);
      device.on('incoming', handleIncoming);
      device.on('tokenWillExpire', handleTokenWillExpire);
      device.on('error', handleError);

      // Allow device to receive calls
      device.register();
      setDevice(device);
    };

    const edge = edge_locations.length > 0 ? edge_locations : 'roaming';
    const options: Device.Options = {
      tokenRefreshMs: 300000,
      closeProtection: true,
      codecPreferences: ['opus', 'pcmu'] as Call.Codec[],
      dscp: true,
      maxAverageBitrate: deviceBitrate,
      edge,
      // TODO: for changing twilio phone sound
      // sounds: {
      //   [Device.SoundName.Outgoing]:
      //     'https://dandelion-snowshoe-4202.twil.io/assets/beep2.mp3',
      // },
    };

    if (!currentToken) {
      const newDevice: Device = new Device(token, options);
      setUpDevice(newDevice);
      setCurrentToken(token);
    } else if (token !== currentToken) {
      device?.updateToken(token);
      setCurrentToken(token);
    }
  }, [
    currentToken,
    device,
    deviceBitrate,
    edge_locations,
    refreshToken,
    setPhoneState,
    token,
  ]);

  useEffect(() => {
    if (!device) return;

    const cleanUpDevice = (): void => {
      const listeners = [
        'registering',
        'registered',
        'unregistered',
        'destroyed',
        'incoming',
        'tokenWillExpire',
        'error',
      ];
      device.destroy();

      device.removeAllListeners(...listeners);
      setDevice(null);
      setCall(null);
      setCurrentToken(null);
    };

    return () => {
      cleanUpDevice();
    };
  }, [device]);

  const loading = device === null && !error;

  return {
    device,
    deviceBitrate,
    setDeviceBitrate,
    call,
    setCall,
    loading,
    error,
    warnings,
  };
};

const isTwilioCallOutgoing = (call: Call | null): boolean => {
  return call?.direction === Call.CallDirection.Outgoing;
};

const useTwilioPhoneCallActions = ({
  user,
  standardizedPhoneNumber,
  setPhoneNumber,
  setPhoneState,
  device,
  call,
  setCall,
}: TwilioPhoneCallActionsProps): PhoneCallActions => {
  const startCall = async ({
    phoneNumber,
    callProviderData,
    callCustomData,
  }: StartCallParams = {}) => {
    if (!device) {
      console.info('No device connected.');
      return;
    }
    if (call) {
      console.info('Call already in progress.');
      return;
    }

    let toNumber = standardizedPhoneNumber;
    if (phoneNumber) {
      toNumber = standardizePhoneNumber(phoneNumber);
      setPhoneNumber(phoneNumber);
    }
    if (!toNumber) {
      console.info(`Invalid phone number: ${toNumber}`);
      return;
    }
    const params: Record<string, string> = {
      To: toNumber,
      agent: user?.email || '',
    };
    if (callProviderData) {
      params.callProviderData = JSON.stringify(callProviderData);
    }
    if (callCustomData) {
      params.callCustomData = JSON.stringify(callCustomData);
    }

    const callOptions: Device.ConnectOptions = {
      params,
      rtcConfiguration: {
        iceTransportPolicy: 'all',
        iceCandidatePoolSize: 1,
      },
      rtcConstraints: {
        audio: true,
      },
    };
    const newCall: Call = await device.connect(callOptions);
    setCall(newCall);
    setPhoneState(STATES.PHONE.ON_CALL);
  };

  const acceptCall = () => {
    if (!call) return;

    call.accept();

    setPhoneState(STATES.PHONE.ON_CALL);
  };

  const rejectCall = () => {
    if (call) call.reject();

    setPhoneState(STATES.PHONE.READY);
  };

  const endCall = () => {
    if (call) call.disconnect();

    setPhoneState(STATES.PHONE.READY);
  };

  const muteCall = (isMuted: boolean) => {
    if (!call) return;

    call.mute(isMuted);
  };

  return { startCall, acceptCall, rejectCall, endCall, muteCall };
};

const useTwilioPhonePadActions = ({
  formattedPhoneNumber,
  setPhoneNumber,
  call,
}: TwilioPhonePadActionsProps): PhonePadActions => {
  const onPadDigitClick = (digit: string) => {
    if (!formattedPhoneNumber) return;

    setPhoneNumber(formattedPhoneNumber + digit);
  };

  const onPadDeleteClick = () => {
    if (!formattedPhoneNumber) return;

    setPhoneNumber(formattedPhoneNumber.slice(0, -1));
  };

  const onCallPadDigitClick = (digit: string) => {
    if (!call) return;

    call.sendDigits(digit);
  };

  return { onPadDigitClick, onPadDeleteClick, onCallPadDigitClick };
};

export const useTwilioCallProvider = (): CallProvider => {
  const { currentUser: user } = useCurrentUser();
  const {
    standardizedPhoneNumber,
    formattedPhoneNumber,
    setPhoneState,
    setPhoneNumber,
  } = usePhoneContext();
  const {
    device,
    deviceBitrate,
    setDeviceBitrate,
    call,
    setCall,
    loading,
    error,
    warnings,
  } = useTwilioDevice();
  const phoneConfigActions = usePhoneConfig(
    device,
    deviceBitrate,
    setDeviceBitrate
  );

  const callActions = useTwilioPhoneCallActions({
    user,
    standardizedPhoneNumber,
    setPhoneNumber,
    setPhoneState,
    device,
    call,
    setCall,
  });
  const padActions = useTwilioPhonePadActions({
    formattedPhoneNumber,
    setPhoneNumber,
    call,
  });

  const callerNumber = call?.parameters?.From;
  const formattedCallerNumber = formatPhoneNumber(callerNumber);

  const onCallNumber = isTwilioCallOutgoing(call)
    ? formattedPhoneNumber
    : formattedCallerNumber;

  return {
    callActions,
    padActions,
    phoneConfigActions,
    loading,
    error,
    warnings,
    name: 'twilio',
    callerNumber,
    onCallNumber,
  };
};
