import { useAssignedPhone } from '../api/available-phones';
import { formatPhoneNumber } from 'src/utils/formatting/phone';
import { useTwilioCallProvider } from './twillio-phone';
import { useContactsWithCalls } from 'hooks/api/contacts/contacts';
import { usePhoneContext } from 'src/contexts/phone-context';
import { CallProvider, MatchedContact } from './types';
import { Route } from 'src/routes/__root';
import { useEffect } from 'react';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { useBridgeCallProvider } from './providers/bridge-call-provider';
import { useBooleanSetting } from 'hooks/settings/settings';
import { SettingName } from 'src/api/generated';

export const useUserPhoneNumber = (): {
  phoneNumber: string;
  error: Error | null;
  loading: boolean;
} => {
  const { phone, error, loading } = useAssignedPhone();
  const phoneNumber = phone ? formatPhoneNumber(phone.number) : '';

  return { phoneNumber, error, loading };
};

export const useCallProvider = (): CallProvider => {
  const { setting: callWithoutInternet } = useBooleanSetting(
    SettingName.CALL_WITHOUT_INTERNET
  );
  // Potentially use any provider
  const callProvider = useTwilioCallProvider();
  const bridgeCallProvider = useBridgeCallProvider();

  if (callWithoutInternet) return bridgeCallProvider;
  return callProvider;
};

export const useMatchContact = (
  validDisplayNumber: string | null
): MatchedContact => {
  const filters: Record<string, FilterParam> = {};
  if (validDisplayNumber) filters.phone = { value: validDisplayNumber };
  const filterBy = generateFilterQueryString(filters);

  const enabled = !!validDisplayNumber;
  const { contacts, loading } = useContactsWithCalls(
    {
      filterBy,
    },
    enabled
  );

  if (!contacts) return { contact: undefined, loading };

  const contact = contacts.find(
    (contact) => contact.phone === validDisplayNumber
  );

  return { contact, loading };
};

export const useSetPhoneNumberFromQueryParams = (): void => {
  const { setPhoneNumber } = usePhoneContext();
  const { phoneNumber } = Route.useSearch();

  useEffect(() => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
    }
  }, [phoneNumber, setPhoneNumber]);

  return;
};
