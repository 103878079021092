/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Analysis } from './Analysis';
import type { Artifact } from './Artifact';
import type { BotMessage } from './BotMessage';
import type { FunctionCallMessage } from './FunctionCallMessage';
import type { FunctionResultMessage } from './FunctionResultMessage';
import type { SystemMessage } from './SystemMessage';
import type { ToolCallMessage } from './ToolCallMessage';
import type { ToolCallResultMessage } from './ToolCallResultMessage';
import type { UserMessage } from './UserMessage';

export type EndOfCallReportServerMessage = {
    /**
     * This is the analysis of the call.
     */
    analysis: Analysis;
    /**
     * This is the summary of the call.
     */
    summary: string;
    /**
     * This is the transcript of the call.
     */
    transcript: string;
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the artifacts from the call.
     */
    artifact?: Artifact;
    /**
     * This is the main `call` object of the call.
     */
    call: Record<string, any>;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record<string, any>;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record<string, any>;
    /**
     * This is the URL of the stereo call recording.
     */
    stereoRecordingUrl?: string;
    /**
     * This is the URL of the call recording.
     */
    recordingUrl?: string;
    /**
     * These are the message history of the call. The format is not OpenAI format but a custom VAPI format.
     */
    messages?: Array<(UserMessage | SystemMessage | BotMessage | FunctionCallMessage | ToolCallMessage | ToolCallResultMessage | FunctionResultMessage)>;
    /**
     * This is the reason the call ended.
     */
    endedReason: EndOfCallReportServerMessage.endedReason;
    /**
     * This is the type of the message. "end-of-call-report" is sent when the call ends and post-processing is complete.
     */
    type: EndOfCallReportServerMessage.type;
};

export namespace EndOfCallReportServerMessage {

    /**
     * This is the reason the call ended.
     */
    export enum endedReason {
        ASSISTANT_ERROR = 'assistant-error',
        ASSISTANT_NOT_FOUND = 'assistant-not-found',
        DB_ERROR = 'db-error',
        NO_SERVER_AVAILABLE = 'no-server-available',
        PIPELINE_ERROR_EXTRA_FUNCTION_FAILED = 'pipeline-error-extra-function-failed',
        PIPELINE_ERROR_FIRST_MESSAGE_FAILED = 'pipeline-error-first-message-failed',
        PIPELINE_ERROR_FUNCTION_FILLER_FAILED = 'pipeline-error-function-filler-failed',
        PIPELINE_ERROR_FUNCTION_FAILED = 'pipeline-error-function-failed',
        PIPELINE_ERROR_OPENAI_LLM_FAILED = 'pipeline-error-openai-llm-failed',
        PIPELINE_ERROR_AZURE_OPENAI_LLM_FAILED = 'pipeline-error-azure-openai-llm-failed',
        PIPELINE_ERROR_TOGETHER_AI_LLM_FAILED = 'pipeline-error-together-ai-llm-failed',
        PIPELINE_ERROR_ANYSCALE_LLM_FAILED = 'pipeline-error-anyscale-llm-failed',
        PIPELINE_ERROR_OPENROUTER_LLM_FAILED = 'pipeline-error-openrouter-llm-failed',
        PIPELINE_ERROR_PERPLEXITY_AI_LLM_FAILED = 'pipeline-error-perplexity-ai-llm-failed',
        PIPELINE_ERROR_DEEPINFRA_LLM_FAILED = 'pipeline-error-deepinfra-llm-failed',
        PIPELINE_ERROR_RUNPOD_LLM_FAILED = 'pipeline-error-runpod-llm-failed',
        PIPELINE_ERROR_GROQ_LLM_FAILED = 'pipeline-error-groq-llm-failed',
        PIPELINE_ERROR_ANTHROPIC_LLM_FAILED = 'pipeline-error-anthropic-llm-failed',
        PIPELINE_ERROR_OPENAI_VOICE_FAILED = 'pipeline-error-openai-voice-failed',
        PIPELINE_ERROR_CARTESIA_VOICE_FAILED = 'pipeline-error-cartesia-voice-failed',
        PIPELINE_ERROR_DEEPGRAM_TRANSCRIBER_FAILED = 'pipeline-error-deepgram-transcriber-failed',
        PIPELINE_ERROR_DEEPGRAM_VOICE_FAILED = 'pipeline-error-deepgram-voice-failed',
        PIPELINE_ERROR_ELEVEN_LABS_VOICE_FAILED = 'pipeline-error-eleven-labs-voice-failed',
        PIPELINE_ERROR_PLAYHT_VOICE_FAILED = 'pipeline-error-playht-voice-failed',
        PIPELINE_ERROR_LMNT_VOICE_FAILED = 'pipeline-error-lmnt-voice-failed',
        PIPELINE_ERROR_AZURE_VOICE_FAILED = 'pipeline-error-azure-voice-failed',
        PIPELINE_ERROR_RIME_AI_VOICE_FAILED = 'pipeline-error-rime-ai-voice-failed',
        PIPELINE_ERROR_NEETS_VOICE_FAILED = 'pipeline-error-neets-voice-failed',
        PIPELINE_NO_AVAILABLE_MODEL = 'pipeline-no-available-model',
        SERVER_SHUTDOWN = 'server-shutdown',
        TWILIO_FAILED_TO_CONNECT_CALL = 'twilio-failed-to-connect-call',
        UNKNOWN_ERROR = 'unknown-error',
        VONAGE_DISCONNECTED = 'vonage-disconnected',
        VONAGE_FAILED_TO_CONNECT_CALL = 'vonage-failed-to-connect-call',
        PHONE_CALL_PROVIDER_BYPASS_ENABLED_BUT_NO_CALL_RECEIVED = 'phone-call-provider-bypass-enabled-but-no-call-received',
        VAPI_ERROR_PHONE_CALL_WORKER_SETUP_SOCKET_ERROR = 'vapi-error-phone-call-worker-setup-socket-error',
        VAPI_ERROR_PHONE_CALL_WORKER_WORKER_SETUP_SOCKET_TIMEOUT = 'vapi-error-phone-call-worker-worker-setup-socket-timeout',
        VAPI_ERROR_PHONE_CALL_WORKER_COULD_NOT_FIND_CALL = 'vapi-error-phone-call-worker-could-not-find-call',
        VAPI_ERROR_PHONE_CALL_WORKER_CALL_NEVER_CONNECTED = 'vapi-error-phone-call-worker-call-never-connected',
        VAPI_ERROR_WEB_CALL_WORKER_SETUP_FAILED = 'vapi-error-web-call-worker-setup-failed',
        ASSISTANT_NOT_INVALID = 'assistant-not-invalid',
        ASSISTANT_NOT_PROVIDED = 'assistant-not-provided',
        CALL_START_ERROR_NEITHER_ASSISTANT_NOR_SERVER_SET = 'call-start-error-neither-assistant-nor-server-set',
        ASSISTANT_REQUEST_FAILED = 'assistant-request-failed',
        ASSISTANT_REQUEST_RETURNED_ERROR = 'assistant-request-returned-error',
        ASSISTANT_REQUEST_RETURNED_UNSPEAKABLE_ERROR = 'assistant-request-returned-unspeakable-error',
        ASSISTANT_REQUEST_RETURNED_INVALID_ASSISTANT = 'assistant-request-returned-invalid-assistant',
        ASSISTANT_REQUEST_RETURNED_NO_ASSISTANT = 'assistant-request-returned-no-assistant',
        ASSISTANT_REQUEST_RETURNED_FORWARDING_PHONE_NUMBER = 'assistant-request-returned-forwarding-phone-number',
        ASSISTANT_ENDED_CALL = 'assistant-ended-call',
        ASSISTANT_SAID_END_CALL_PHRASE = 'assistant-said-end-call-phrase',
        ASSISTANT_FORWARDED_CALL = 'assistant-forwarded-call',
        ASSISTANT_JOIN_TIMED_OUT = 'assistant-join-timed-out',
        CUSTOMER_BUSY = 'customer-busy',
        CUSTOMER_ENDED_CALL = 'customer-ended-call',
        CUSTOMER_DID_NOT_ANSWER = 'customer-did-not-answer',
        CUSTOMER_DID_NOT_GIVE_MICROPHONE_PERMISSION = 'customer-did-not-give-microphone-permission',
        INCOMING_CLIENT_MESSAGE_SAY_ENDED_CALL = 'incoming-client-message-say-ended-call',
        EXCEEDED_MAX_DURATION = 'exceeded-max-duration',
        MANUALLY_CANCELED = 'manually-canceled',
        PHONE_CALL_PROVIDER_CLOSED_WEBSOCKET = 'phone-call-provider-closed-websocket',
        PIPELINE_ERROR_CUSTOM_LLM_LLM_FAILED = 'pipeline-error-custom-llm-llm-failed',
        PIPELINE_ERROR_ELEVEN_LABS_VOICE_NOT_FOUND = 'pipeline-error-eleven-labs-voice-not-found',
        PIPELINE_ERROR_ELEVEN_LABS_QUOTA_EXCEEDED = 'pipeline-error-eleven-labs-quota-exceeded',
        PIPELINE_ERROR_ELEVEN_LABS_BLOCKED_FREE_PLAN = 'pipeline-error-eleven-labs-blocked-free-plan',
        PIPELINE_ERROR_ELEVEN_LABS_BLOCKED_CONCURRENT_REQUESTS = 'pipeline-error-eleven-labs-blocked-concurrent-requests',
        PIPELINE_ERROR_ELEVEN_LABS_UNAUTHORIZED_ACCESS = 'pipeline-error-eleven-labs-unauthorized-access',
        PIPELINE_ERROR_ELEVEN_LABS_SYSTEM_BUSY_AND_REQUESTED_UPGRADE = 'pipeline-error-eleven-labs-system-busy-and-requested-upgrade',
        PIPELINE_ERROR_ELEVEN_LABS_VOICE_NOT_FINE_TUNED = 'pipeline-error-eleven-labs-voice-not-fine-tuned',
        PIPELINE_ERROR_ELEVEN_LABS_INVALID_API_KEY = 'pipeline-error-eleven-labs-invalid-api-key',
        PIPELINE_ERROR_PLAYHT_REQUEST_TIMED_OUT = 'pipeline-error-playht-request-timed-out',
        PIPELINE_ERROR_PLAYHT_INVALID_VOICE = 'pipeline-error-playht-invalid-voice',
        PIPELINE_ERROR_PLAYHT_UNEXPECTED_ERROR = 'pipeline-error-playht-unexpected-error',
        PIPELINE_ERROR_PLAYHT_OUT_OF_CREDITS = 'pipeline-error-playht-out-of-credits',
        PIPELINE_ERROR_PLAYHT_RATE_LIMIT_EXCEEDED = 'pipeline-error-playht-rate-limit-exceeded',
        PIPELINE_ERROR_PLAYHT_502_GATEWAY_ERROR = 'pipeline-error-playht-502-gateway-error',
        PIPELINE_ERROR_PLAYHT_504_GATEWAY_ERROR = 'pipeline-error-playht-504-gateway-error',
        SILENCE_TIMED_OUT = 'silence-timed-out',
        VOICEMAIL = 'voicemail',
        VONAGE_REJECTED = 'vonage-rejected',
    }

    /**
     * This is the type of the message. "end-of-call-report" is sent when the call ends and post-processing is complete.
     */
    export enum type {
        END_OF_CALL_REPORT = 'end-of-call-report',
    }


}

