import { Input } from '@material-tailwind/react';
import React from 'react';

interface InlineTextInputProps {
  value: string;
  onChange: (newValue: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  label?: string;
}

const InlineTextInput: React.FC<InlineTextInputProps> = ({
  value,
  onChange,
  onKeyDown,
  className,
  containerClassName,
  labelClassName,
  placeholder,
  label,
}) => {
  const inputClasses = `h-9 focus:border-t-transparent focus:border-blue-500 ${className}`;
  const labelClasses = `
    before:!h-9 after:!h-9 before:mt-1.5 after:mt-1.5
    before:!border-t-1 after:!border-t-1
    peer-focus:before:!border-t-2 peer-focus:after:!border-t-2
    before:!border-transparent after:!border-transparent
    before:!border-t-blue-gray-200 after:!border-t-blue-gray-200
    peer-focus:before:!border-blue-500 peer-focus:after:!border-blue-500
    peer-focus:text-blue-gray-300
    ${labelClassName} ${!label ? 'hidden' : ''}
  `;
  const placeholderValue = !label ? placeholder : '';
  return (
    <Input
      label={label}
      placeholder={placeholderValue}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      className={inputClasses}
      labelProps={{ className: labelClasses }}
      containerProps={{
        className: `h-auto ${containerClassName}`,
      }}
      crossOrigin={undefined}
    />
  );
};

export default InlineTextInput;
