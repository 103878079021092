import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../buttons/login-button';
import InfoLoading from 'components/atoms/info-loading';

interface AuthenticatedWrapperProps {
  children: React.ReactNode;
  openInPopup?: boolean;
  className?: string;
}

const AuthenticatedWrapper: React.FC<AuthenticatedWrapperProps> = ({
  children,
  openInPopup = false,
  className = '',
}) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <InfoLoading />;
  }
  if (isAuthenticated) {
    return children;
  }

  const classes = `items-start justify-center px-6 py-10 ${className}`;
  return (
    <div className={classes}>
      <LoginButton openInPopup={openInPopup} />
    </div>
  );
};

export default AuthenticatedWrapper;
