// /src/utils/date.ts

// TODO: check when implementing i18n
enum DAYS_TAGS {
  SUNDAY = 'Domingo',
  MONDAY = 'Lunes',
  TUESDAY = 'Martes',
  WEDNESDAY = 'Miércoles',
  THURSDAY = 'Jueves',
  FRIDAY = 'Viernes',
  SATURDAY = 'Sábado',
  TODAY = 'Hoy',
}

// TODO: check when implementing i18n
enum MONTHS_TAGS {
  JANUARY = 'Enero',
  FEBRUARY = 'Febrero',
  MARCH = 'Marzo',
  APRIL = 'Abril',
  MAY = 'Mayo',
  JUNE = 'Junio',
  JULY = 'Julio',
  AUGUST = 'Agosto',
  SEPTEMBER = 'Septiembre',
  OCTOBER = 'Octubre',
  NOVEMBER = 'Noviembre',
  DECEMBER = 'Diciembre',
}

export const getDayName = (dayIndex: number): string => {
  const dayNames = [
    DAYS_TAGS.SUNDAY,
    DAYS_TAGS.MONDAY,
    DAYS_TAGS.TUESDAY,
    DAYS_TAGS.WEDNESDAY,
    DAYS_TAGS.THURSDAY,
    DAYS_TAGS.FRIDAY,
    DAYS_TAGS.SATURDAY,
  ];
  return dayNames[dayIndex];
};

export const getMonthName = (monthIndex: number): string => {
  const monthNames = [
    MONTHS_TAGS.JANUARY,
    MONTHS_TAGS.FEBRUARY,
    MONTHS_TAGS.MARCH,
    MONTHS_TAGS.APRIL,
    MONTHS_TAGS.MAY,
    MONTHS_TAGS.JUNE,
    MONTHS_TAGS.JULY,
    MONTHS_TAGS.AUGUST,
    MONTHS_TAGS.SEPTEMBER,
    MONTHS_TAGS.OCTOBER,
    MONTHS_TAGS.NOVEMBER,
    MONTHS_TAGS.DECEMBER,
  ];

  return monthNames[monthIndex];
};

export const SEC_IN_MS = 1000;
export const MINUTE_IN_MS = 1000 * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;

export type TimeFrame = 'daily' | 'weekly' | 'monthly';

export const getStartOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getEndOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const getStartOfWeek = (date: Date): Date => {
  const newDate = new Date(date);
  const dayOfWeek = newDate.getDay();
  const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  newDate.setDate(newDate.getDate() + diff);
  return getStartOfDay(newDate);
};

export const getStartOfMonth = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setDate(1);
  return getStartOfDay(newDate);
};

export const getEndOfMonth = (date: Date): Date => {
  const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return getEndOfDay(newDate);
};

export const parseDate = (
  dateString: string | undefined | null
): Date | null => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return null;
  }
  const date = new Date(dateString);
  return date;
};

const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const formatDateLabel = (day: string): string => {
  const date = new Date(day);
  const dayName = getDayName(date.getUTCDay());
  return isToday(date) ? `${dayName} (${DAYS_TAGS.TODAY})` : dayName;
};

const getDailyDateRange = (
  currentDate: Date,
  endDate: Date,
  periodLength: number
): { startDate: Date; endDate: Date } => {
  const startDate = getStartOfDay(currentDate);
  startDate.setDate(startDate.getDate() - (periodLength - 1));
  return { startDate, endDate };
};

const getWeeklyDateRange = (
  currentDate: Date,
  endDate: Date,
  periodLength: number
): { startDate: Date; endDate: Date } => {
  const startDate = getStartOfWeek(currentDate);
  startDate.setDate(startDate.getDate() - (periodLength - 1) * 7);
  return { startDate, endDate };
};

const getMonthlyDateRange = (
  currentDate: Date,
  endDate: Date,
  periodLength: number
): { startDate: Date; endDate: Date } => {
  const startDate = getStartOfMonth(currentDate);
  startDate.setMonth(startDate.getMonth() - (periodLength - 1));
  return { startDate, endDate };
};

export const getDateRange = (type: TimeFrame, periodLength: number = 6) => {
  const currentDate = new Date();
  const endDate = getEndOfDay(currentDate);

  const dateFunctions = {
    daily: () => getDailyDateRange(currentDate, endDate, periodLength),
    weekly: () => getWeeklyDateRange(currentDate, endDate, periodLength),
    monthly: () => getMonthlyDateRange(currentDate, endDate, periodLength),
  };

  return dateFunctions[type]();
};

export const getHoursToDate = (date: Date): number => {
  const currentTime = new Date().getTime();
  const time = date.getTime();

  return Math.floor((time - currentTime) / HOUR_IN_MS);
};
