import { Device } from '@twilio/voice-sdk';
import { useSelectAudioDevices } from './twilio-audio-devices';
import { PhoneConfigActions } from './types';

export const usePhoneConfig = (
  device: Device | null,
  deviceBitrate: number,
  setDeviceBitrate: (bitrate: number) => void
): PhoneConfigActions => {
  const {
    selectedInputDevice,
    setSelectedInputDevice,
    selectedOutputDevice,
    setSelectedOutputDevice,
  } = useSelectAudioDevices(device);

  return {
    selectedInputDevice,
    setSelectedInputDevice,
    selectedOutputDevice,
    setSelectedOutputDevice,
    selectedDeviceBitrate: deviceBitrate,
    setDeviceBitrate,
  };
};
