import { Button, ButtonGroup } from '@material-tailwind/react';
import { PhoneArrowDownLeftIcon, PhoneIcon } from '@heroicons/react/24/solid';
import CallMenu from 'components/molecules/calls/call-menu';

interface IconCallButtonProps {
  startCall: () => void;
  disabled: boolean;
  callWithoutInternetChoose: boolean;
  callingWithoutInternet: boolean;
  updateSetting: (value: boolean) => void;
}

const IconCallButton = ({
  startCall,
  disabled = false,
  callWithoutInternetChoose,
  callingWithoutInternet,
  updateSetting,
}: IconCallButtonProps) => {
  const disabledClasses = disabled
    ? 'cursor-not-allowed'
    : 'bg-green-500 hover:bg-green-600';
  const buttonClasses = `flex-1 p-2 rounded-l-lg ${disabledClasses}`;

  if (callWithoutInternetChoose) {
    return (
      <ButtonGroup>
        <Button
          disabled={disabled}
          onClick={() => startCall()}
          className={buttonClasses}
        >
          {callingWithoutInternet ? (
            <PhoneArrowDownLeftIcon className="h-6 w-6" />
          ) : (
            <PhoneIcon className="h-6 w-6" />
          )}
        </Button>
        <CallMenu
          callingWithoutInternet={callingWithoutInternet}
          updateSetting={updateSetting}
          iconButtonClasses={'w-6'}
        />
      </ButtonGroup>
    );
  }

  return (
    <Button
      disabled={disabled}
      onClick={() => startCall()}
      className={buttonClasses}
    >
      <PhoneIcon className="h-6 w-6" />
    </Button>
  );
};

export default IconCallButton;
