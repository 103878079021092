import { XCircleIcon } from '@heroicons/react/24/solid';
import Button from 'components/atoms/buttons/button';
import TextInput from 'components/atoms/forms/text-input';

interface DisplayProps {
  value: string;
  initialValue: string;
  onChange: (value: string) => void;
  onClear: () => void;
  className?: string;
}

const Display: React.FC<DisplayProps> = ({
  value,
  initialValue,
  onChange,
  onClear,
  className = '',
}) => {
  const inputClasses =
    'rounded-none border-blue-600 border-b-1 ' +
    'pt-2 pl-2 pr-8 h-10 text-center text-lg text-blue-600 ' +
    'focus:bg-blue-50/80 hover:bg-blue-50/80 ';

  return (
    <div className={`relative flex items-center ${className}`}>
      <TextInput
        variant="static"
        value={value}
        onChange={onChange}
        className={inputClasses}
        // TODO: check when implementing i18n
        placeholder="+56..."
      />
      {value !== initialValue && (
        <Button
          onClick={onClear}
          className="absolute right-0 mr-1 text-red-300 hover:text-red-900"
        >
          <XCircleIcon strokeWidth={1} className={'h-6 w-6'} />
        </Button>
      )}
    </div>
  );
};

export default Display;
