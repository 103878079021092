import React, { useEffect, useState } from 'react';
import ViewContainer from 'components/atoms/view-container';
import CallsSummaryDailyChart from 'components/molecules/charts/calls-summary-daily-chart';
import CallsSummaryWeeklyChart from 'components/molecules/charts/calls-summary-weekly-chart';
import AgentSelect from 'components/molecules/charts/agent-select';
import { useCallSummaries } from 'hooks/api/call-summaries';
import { getDateRange } from 'src/utils/date';
import CallTypeSelect from 'components/molecules/charts/call-type-select';
import InfoLoading from 'components/atoms/info-loading';
import InfoError from 'components/atoms/info-error';
import AgentCallsDailyChart from 'components/molecules/charts/agent-calls-daily-chart';
import AgentCallsWeeklyChart from 'components/molecules/charts/agent-calls-weekly-chart';
import CallsSummaryMonthlyChart from 'components/molecules/charts/calls-summary-monthly-chart';
import AgentCallsMonthlyChart from 'components/molecules/charts/agent-calls-monthly-chart';
import {
  useAgentFilteredCalls,
  useAgentSorting,
  useCallTypeFilteredCalls,
} from 'hooks/charts/filters';
import { periodLengths } from 'src/utils/charts';
import ViewFilter from 'components/atoms/view-filter';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels
);

// this range should include all others, and then daily and
// weekly will filter out their data from here
const initialDateRange = getDateRange('monthly', periodLengths.monthly);

const ReportsView: React.FC = () => {
  // TODO: implement date range picker
  const [dateRange] = useState<Array<Date>>([
    initialDateRange.startDate,
    initialDateRange.endDate,
  ]);
  const { summaries, loading, error } = useCallSummaries(
    dateRange[0].toISOString(),
    dateRange[1].toISOString()
  );

  const { selectedAgents, setSelectedAgents, filteredSummariesByAgent } =
    useAgentFilteredCalls(summaries);
  const { selectedCallType, setSelectedCallType, filteredSummariesByCallType } =
    useCallTypeFilteredCalls(filteredSummariesByAgent);

  const sortedAgents = useAgentSorting(summaries);
  useEffect(() => {
    if (sortedAgents.length === 0) return;

    const allAgentIds = sortedAgents.map((agent) => agent.id);
    setSelectedAgents(allAgentIds);
  }, [setSelectedAgents, sortedAgents]);

  const headerElement = (
    <ViewFilter>
      <CallTypeSelect
        selectedCallType={selectedCallType}
        onCallTypeChange={setSelectedCallType}
      />
      <AgentSelect
        agents={sortedAgents}
        selectedAgents={selectedAgents}
        onAgentSelectionChange={setSelectedAgents}
      />
    </ViewFilter>
  );

  if (loading) {
    return <InfoLoading />;
  }

  if (error) {
    return <InfoError error={error} />;
  }

  return (
    <>
      <ViewContainer title="Reportes" headerElement={headerElement}>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 overflow-y-auto">
          <div className="p-4">
            <CallsSummaryDailyChart summaries={filteredSummariesByCallType} />
          </div>
          <div className="p-4">
            <AgentCallsDailyChart summaries={filteredSummariesByCallType} />
          </div>
          <div className="p-4">
            <CallsSummaryWeeklyChart summaries={filteredSummariesByCallType} />
          </div>
          <div className="p-4">
            <AgentCallsWeeklyChart summaries={filteredSummariesByCallType} />
          </div>
          <div className="p-4">
            <CallsSummaryMonthlyChart summaries={filteredSummariesByCallType} />
          </div>
          <div className="p-4">
            <AgentCallsMonthlyChart summaries={filteredSummariesByCallType} />
          </div>
        </div>
      </ViewContainer>
    </>
  );
};

export default ReportsView;
